import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import getFacilities from './getFacilities';
import type { FacilityLocationResponse } from './type';

const useGetFacilities = (
  businessId: string,
  showInformation: boolean,
  queryOptions?: UseQueryOptions<FacilityLocationResponse[], Error>,
): UseQueryResult<FacilityLocationResponse[], Error> =>
  useQuery<FacilityLocationResponse[], Error>(
    [`getFacilities/${businessId}`],
    // eslint-disable-next-line @typescript-eslint/promise-function-async
    () => getFacilities(businessId, showInformation),
    queryOptions,
  );

export default useGetFacilities;
