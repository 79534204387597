import InputMask from '@components/Forms/InputMask';
import InputText from '@components/Forms/InputText';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { UseFormGetValues } from 'react-hook-form';
import type { FieldErrors } from 'react-hook-form/dist/types/errors';
import type { UseFormRegister } from 'react-hook-form/dist/types/form';
import type { FormSchema, PrimaryBusinessType } from '../types';

interface InputFieldProps {
  business: PrimaryBusinessType;
  volume: PrimaryBusinessType;
  register: UseFormRegister<FormSchema>;
  errors: FieldErrors<FormSchema>;
  getValues: UseFormGetValues<FormSchema>;
  required?: boolean;
  disabled?: boolean;
  disabledVolume?: boolean;
  errorFlag?: boolean;
  errorFlagVolume?: boolean;
}

function InputField(props: InputFieldProps): JSX.Element {
  const {
    register,
    errors,
    business,
    volume,
    getValues,
    required = false,
    disabled = false,
    disabledVolume = false,
    errorFlag = false,
    errorFlagVolume = false,
  } = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: matches ? 'row' : 'column',
          gap: '55px',
        }}
      >
        <Grid item>
          <InputText
            {...register(business)}
            fieldErrors={errors[business]}
            helperText={errors[business]?.message}
            showHelperText
            width={256}
            required={required}
            label="Business Name"
            disabled={disabled}
            errorFlag={errorFlag}
          />
        </Grid>
        <Grid item>
          <InputMask
            {...register(volume)}
            fieldErrors={errors[volume]}
            helperText={errors[volume]?.message}
            showHelperText
            width={350}
            type="number"
            label="Anticipated Monthly Transaction Volume"
            value={getValues(volume)}
            required={required}
            disabled={disabledVolume}
            errorFlag={errorFlagVolume}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default InputField;
