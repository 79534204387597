import { z } from 'zod';

import { COUNTRY_LIST } from '@/constants/us-countries';
import { US_STATES } from '@/constants/us-states';
import { baseAddressFormSchema } from '@components/Forms/Address/AddressForm/type';
import { baseMailingAddressFormSchema } from '@components/Forms/Address/MailingAddressForm/type';
import { FIXED_COUNTRY } from '@/constants/misc';

export const mailingNullableFormSchema = z.object({
  'mailing-city': z.string().nullable(),
  'mailing-country': z.enum(COUNTRY_LIST).nullable().default(FIXED_COUNTRY),
  'mailing-postal-code': z.string().nullable(),
  'mailing-state': z
    .enum(US_STATES, {
      errorMap: () => {
        return { message: 'Please select a State' };
      },
    })
    .nullable(),
  'mailing-street-address': z.string().nullable(),
  'mailing-unit-number': z.string().nullable(),
});

export const addressWithOutMailingFormSchema = baseAddressFormSchema.merge(
  mailingNullableFormSchema,
);
export const addressWithMailingFormSchema = baseAddressFormSchema.merge(
  baseMailingAddressFormSchema,
);
