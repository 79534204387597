import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';

import type {
  UpdateFacilityInformationResponse,
  UpdateFacilityInformationVariables,
} from './types';

import updateFacilityInformation from './updateFacilityInformation';

const useUpdateFacilityInformation = (): UseMutationResult<
  UpdateFacilityInformationResponse,
  Error,
  UpdateFacilityInformationVariables
> =>
  useMutation<
    UpdateFacilityInformationResponse,
    Error,
    UpdateFacilityInformationVariables
  >(updateFacilityInformation);

export default useUpdateFacilityInformation;
