import type { InputBaseComponentProps } from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import { forwardRef, useMemo } from 'react';

import { CSS_VARS } from '@/constants/css-vars';

import HelperText from '../HelperText';

import { getInputStatus, isInputInvalid as isInputInvalidUtil } from '../utils';

import { inputStyles, StyledInputText } from './styles';

import type { TextInputProps } from './types';
import Tooltip from '@/components/Tooltip';
import IconButton from '@mui/material/IconButton';
import { ICONS } from '@/constants/icons';

const DEFAULT_INPUT_PROPS: InputBaseComponentProps = {
  style: inputStyles,
};

interface ExtendedInputTextProps extends TextInputProps {
  preventPaste?: boolean;
}

const InputText = forwardRef<
  HTMLInputElement,
  ExtendedInputTextProps & { errorFlag?: boolean }
>((props, ref): JSX.Element => {
  const {
    disabled,
    endAdornment,
    fieldErrors,
    helperText,
    hideLabel = false,
    label,
    name,
    toolTipText,
    required = false,
    showHelperText = false,
    type = 'text',
    width,
    preventPaste,
    errorFlag = false,
    inputProps = {},
    ...restOfProps
  } = props;

  const describedBy = `${name}-helper-text`;

  const isInputInvalid = isInputInvalidUtil(fieldErrors);

  const status = getInputStatus(isInputInvalid);

  const widthToRender = width ?? '20rem';

  const combinedInputProps: InputBaseComponentProps = useMemo(
    () => ({
      ...inputProps,
      style: {
        ...DEFAULT_INPUT_PROPS.style,
        ...inputProps.style,
      },
    }),
    [inputProps],
  );

  return (
    <div style={{ width: widthToRender }}>
      {!hideLabel && (
        <InputLabel
          disabled={disabled}
          htmlFor={name}
          required={required}
          sx={{
            color: required ? `var(${CSS_VARS.Colors.Error.Dark})` : 'inherit',
            whiteSpace: 'unset',
          }}
        >
          <Typography display="inline" fontWeight={700} variant="body2">
            {label}
          </Typography>

          {toolTipText != null && (
            <Tooltip placement="right" title={toolTipText}>
              <IconButton>
                <ICONS.Info fontSize={'1.5rem'} color={'#37BEDD'} />
              </IconButton>
            </Tooltip>
          )}
        </InputLabel>
      )}

      <StyledInputText
        aria-describedby={describedBy}
        disabled={disabled}
        endAdornment={endAdornment}
        error={isInputInvalid ?? errorFlag}
        fullWidth
        id={name}
        inputProps={combinedInputProps}
        name={name}
        inputRef={ref}
        required={required}
        type={type}
        onPaste={(e) => {
          if (preventPaste === true) {
            e.preventDefault();
            return false;
          }
          return true;
        }}
        {...restOfProps}
      />

      {showHelperText && (
        <HelperText
          describedBy={describedBy}
          helperText={helperText}
          status={status}
        />
      )}
    </div>
  );
});

if (import.meta.env.DEV) {
  InputText.displayName = 'InputText';
}

export default InputText;
