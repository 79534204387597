import Grid from '@mui/material/Grid';

import { ChildSectionsCounter, MainPageListIterator } from './components';

import type { Page } from './types';

interface PaginationProps {
  mainPageList: Page[];
  /** @default 1 */
  activeMainPage?: number;
  /** @default 1 */
  currentChildSection?: number | null;
  /** @default 1 */
  totalChildSections?: number;
}

const Pagination = (props: PaginationProps): JSX.Element => {
  const {
    mainPageList,
    activeMainPage = 1,
    currentChildSection = 1,
    totalChildSections = 1,
  } = props;

  return (
    <Grid alignItems="center" container gap={3} marginBottom="2.4rem">
      {currentChildSection !== null && (
        <ChildSectionsCounter
          currentSection={currentChildSection}
          totalSections={totalChildSections}
        />
      )}

      <MainPageListIterator
        activePage={activeMainPage}
        pageList={mainPageList}
      />
    </Grid>
  );
};

export default Pagination;
