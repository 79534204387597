import { zodResolver } from '@hookform/resolvers/zod';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import ControlBar from '@/components/UI/ControlBar';

import { CSS_VARS } from '@/constants/css-vars';
import { Router } from '@/constants/router';

import useAuthenticatedUser from '@/api/hooks/useAuthenticateUser';
import useGetBusinessFacilityInformation from '@/api/hooks/useGetBusinessFacilityInformation';
import useUpdateFacilityInformation from '@/api/hooks/useUpdateFacilityInformation';

import { useFacilitySectionData } from '@/pages/Facilities/hooks/useFacilitySectionData';

import { BANKING_ACTIVITY_SECTION_ID } from '../data';

import {
  BANKING_ACTIVITY_PAGE_ID,
  formSchema,
  getNormalizedData,
  setBankingActivityValues,
} from './data';

import InputField from './InputField';

import useGetApplicationFeedbacks from '@/api/hooks/useGetApplicationFeedbacks';
import useGetBusiness from '@/api/hooks/useGetBusiness';
import useGetBusinessApplicationProgress from '@/api/hooks/useGetBusinessApplicationProgress';
import useGetBusinessValidation from '@/api/hooks/useGetBusinessValidation';
import { ApplicationStatusEnum } from '@/api/hooks/useGetBusinessValidation/type';
import useUpdateBusinessApplicationProgress from '@/api/hooks/useUpdateBusinessApplicationProgress';
import type { FormSchema } from './types';

function BankingActivityDetail(): JSX.Element {
  const navigate = useNavigate();

  const authenticatedUser = useAuthenticatedUser();

  const { id: businessId } = authenticatedUser.data?.businesses?.[0] ?? {};

  const updateFacilityInformation = useUpdateFacilityInformation();

  const { facilityId } = useFacilitySectionData({
    title: 'Tell us more about your banking activity.',
    description: 'Please complete the following to the best of your ability',
    pageNumber: 17,
  });

  const updateBusinessApplicationProgress =
    useUpdateBusinessApplicationProgress();

  const {
    data: businessApplicationProgress,
    refetch: refetchBusinessProgress,
  } = useGetBusinessApplicationProgress(businessId ?? '', {
    enabled: businessId != null,
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    getValues,
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
  });

  const { data: businessFacilityInformation } =
    useGetBusinessFacilityInformation(
      businessId ?? '',
      facilityId ?? '',
      BANKING_ACTIVITY_SECTION_ID,
      BANKING_ACTIVITY_PAGE_ID,
      {
        enabled: businessId != null && facilityId != null,
        onSuccess(businessFacilityInformation) {
          setBankingActivityValues(businessFacilityInformation, setValue);
        },
      },
    );

  const isLoading = updateFacilityInformation.isLoading;

  const { data: businessValidation } = useGetBusinessValidation(
    businessId ?? '',
    {
      enabled: businessId != null,
    },
  );

  const { data: applicationFeedbacks } = useGetApplicationFeedbacks(
    facilityId ?? '',
    {
      enabled:
        facilityId != null &&
        businessValidation?.status ===
          ApplicationStatusEnum.REVIEW_INFO_REQUESTED,
    },
  );

  const { data: business } = useGetBusiness({ businessId });

  const hasFinishedOnboard = business?.finishedOnboard;

  const isApplicationResubmission =
    businessValidation?.status === ApplicationStatusEnum.REVIEW_INFO_REQUESTED;

  const onSubmitHandler: SubmitHandler<FormSchema> = (data): void => {
    if (businessId == null || facilityId == null) return;

    const sectionData = getNormalizedData(data);

    updateFacilityInformation.mutate(
      {
        businessId,
        facilityId,
        section: sectionData,
      },
      {
        async onSuccess() {
          await updateBusinessApplicationProgress.mutateAsync(
            {
              businessId,
              pagesCompleted:
                businessFacilityInformation?.id !== '' ||
                authenticatedUser?.data?.businesses?.[0]?.finishedOnboard
                  ? undefined
                  : (businessApplicationProgress?.pagesCompleted ?? 0) + 1,
            },
            {
              onSuccess() {
                refetchBusinessProgress().catch(console.error);
                navigate(
                  Router.Onboarding.Facilities.BankingActivity.PrimaryBusiness,
                );
              },
            },
          );
        },
      },
    );
  };

  return (
    <>
      <Grid
        alignItems="flex-start"
        container
        flexDirection="column"
        padding={0}
        width="763px"
      >
        <Grid
          borderBottom={`0.1rem solid var(${CSS_VARS.Colors.Gray[100]})`}
          container
          gap="10px"
          alignItems="flex-end"
          justifyContent="flex-end"
          padding="10px 0"
          width="763px"
          height="60px"
        >
          <Typography
            color={`var(${CSS_VARS.Colors.BlackBlue})`}
            fontWeight={700}
            width="259px"
            textAlign="center"
            fontSize="14px"
          >
            Anticipated amount per month
            <span style={{ color: `var(${CSS_VARS.Colors.Error.Dark})` }}>
              *
            </span>
          </Typography>

          <Typography
            color={`var(${CSS_VARS.Colors.BlackBlue})`}
            fontWeight={700}
            textAlign="left"
            width="190px"
            fontSize="14px"
          >
            Estimated number of transactions per month
            <span style={{ color: `var(${CSS_VARS.Colors.Error.Dark})` }}>
              *
            </span>
          </Typography>
        </Grid>

        <Grid>
          <InputField
            title="Regular Cash Deposits"
            register={register}
            errors={errors}
            amount="regular-cash-amount"
            transactions="regular-cash-transactions"
            getValues={getValues}
            disabled={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'regular-cash-amount',
                  ).length
                : false
            }
            errorFlag={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !!(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'regular-cash-amount',
                  ).length
                : false
            }
            disabledTransaction={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !(applicationFeedbacks ?? []).filter(
                    (feedback) =>
                      feedback.section === 'regular-cash-transactions',
                  ).length
                : false
            }
            errorFlagTransaction={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !!(applicationFeedbacks ?? []).filter(
                    (feedback) =>
                      feedback.section === 'regular-cash-transactions',
                  ).length
                : false
            }
          />

          <InputField
            title="Cash Withdrawals"
            register={register}
            errors={errors}
            amount="cash-withdrawals-amount"
            transactions="cash-withdrawals-transactions"
            getValues={getValues}
            greyBackground
            disabled={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !(applicationFeedbacks ?? []).filter(
                    (feedback) =>
                      feedback.section === 'cash-withdrawals-amount',
                  ).length
                : false
            }
            errorFlag={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !!(applicationFeedbacks ?? []).filter(
                    (feedback) =>
                      feedback.section === 'cash-withdrawals-amount',
                  ).length
                : false
            }
            disabledTransaction={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !(applicationFeedbacks ?? []).filter(
                    (feedback) =>
                      feedback.section === 'cash-withdrawals-transactions',
                  ).length
                : false
            }
            errorFlagTransaction={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !!(applicationFeedbacks ?? []).filter(
                    (feedback) =>
                      feedback.section === 'cash-withdrawals-transactions',
                  ).length
                : false
            }
          />

          <InputField
            title="Incoming ACH (e.g. paid invoices)"
            register={register}
            errors={errors}
            amount="incoming-ach-amount"
            transactions="incoming-ach-transactions"
            getValues={getValues}
            disabled={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'incoming-ach-amount',
                  ).length
                : false
            }
            errorFlag={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !!(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'incoming-ach-amount',
                  ).length
                : false
            }
            disabledTransaction={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !(applicationFeedbacks ?? []).filter(
                    (feedback) =>
                      feedback.section === 'incoming-ach-transactions',
                  ).length
                : false
            }
            errorFlagTransaction={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !!(applicationFeedbacks ?? []).filter(
                    (feedback) =>
                      feedback.section === 'incoming-ach-transactions',
                  ).length
                : false
            }
          />

          <InputField
            title="Outgoing ACH (e.g. Payroll)"
            register={register}
            errors={errors}
            amount="outgoing-ach-amount"
            transactions="outgoing-ach-transactions"
            getValues={getValues}
            greyBackground
            disabled={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'outgoing-ach-amount',
                  ).length
                : false
            }
            errorFlag={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !!(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'outgoing-ach-amount',
                  ).length
                : false
            }
            disabledTransaction={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !(applicationFeedbacks ?? []).filter(
                    (feedback) =>
                      feedback.section === 'outgoing-ach-transactions',
                  ).length
                : false
            }
            errorFlagTransaction={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !!(applicationFeedbacks ?? []).filter(
                    (feedback) =>
                      feedback.section === 'outgoing-ach-transactions',
                  ).length
                : false
            }
          />

          <InputField
            title="Incoming/Outgoing Wires"
            register={register}
            errors={errors}
            amount="wires-amount"
            transactions="wires-transactions"
            getValues={getValues}
            disabled={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'wires-amount',
                  ).length
                : false
            }
            errorFlag={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !!(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'wires-amount',
                  ).length
                : false
            }
            disabledTransaction={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'wires-transactions',
                  ).length
                : false
            }
            errorFlagTransaction={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !!(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'wires-transactions',
                  ).length
                : false
            }
          />

          <InputField
            title="Mobile or Remote Deposit (Check) MRDC"
            register={register}
            errors={errors}
            amount="mrdc-amount"
            transactions="mrdc-transactions"
            getValues={getValues}
            greyBackground
            disabled={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'mrdc-amount',
                  ).length
                : false
            }
            errorFlag={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !!(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'mrdc-amount',
                  ).length
                : false
            }
            disabledTransaction={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'mrdc-transactions',
                  ).length
                : false
            }
            errorFlagTransaction={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !!(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'mrdc-transactions',
                  ).length
                : false
            }
          />

          <InputField
            title="ATM Usage (Pulling money out of ATM)"
            register={register}
            errors={errors}
            amount="atm-amount"
            transactions="atm-transactions"
            getValues={getValues}
            disabled={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'atm-amount',
                  ).length
                : false
            }
            errorFlag={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !!(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'atm-amount',
                  ).length
                : false
            }
            disabledTransaction={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'atm-transactions',
                  ).length
                : false
            }
            errorFlagTransaction={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !!(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'atm-transactions',
                  ).length
                : false
            }
          />
        </Grid>
      </Grid>

      <ControlBar
        showInfoText={!hasFinishedOnboard && !isApplicationResubmission}
        nextButtonProps={{
          onClick: handleSubmit(onSubmitHandler),
          disabled:
            updateBusinessApplicationProgress.isLoading ||
            updateFacilityInformation.isLoading,
        }}
        backButtonProps={{
          onClick() {
            navigate(
              Router.Onboarding.Facilities.BankingActivity.PrimaryPurpose,
            );
          },
        }}
        isLoading={isLoading}
      />
    </>
  );
}

export default BankingActivityDetail;
