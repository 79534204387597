import type { BusinessDocumentType } from '@/types/business';

import fetcher from '@/utils/fetcher';

import type {
  UpdateBusinessDocument,
  UpdateBusinessDocumentVariables,
} from './type';

// eslint-disable-next-line @typescript-eslint/promise-function-async
const updateBusinessDocument = <
  DT extends BusinessDocumentType = BusinessDocumentType,
>({
  businessId,
  documentId,
  businessDocument,
}: UpdateBusinessDocumentVariables): Promise<UpdateBusinessDocument<DT>> =>
  fetcher<UpdateBusinessDocument<DT>>({
    body: businessDocument,
    endpoint:
      documentId != null
        ? `/business/${businessId}/documents/${documentId}/versions`
        : `/business/${businessId}/documents/`,
    method: 'POST',
    headers: {
      Accept: '*/*',
    },
    removeContentType: true,
    getDetailedErrors: true,
  });

export default updateBusinessDocument;
