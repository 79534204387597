import { ReactComponent as EmptyCheckboxIcon } from '@/assets/icons/empty_checkbox_icon.svg';
import type { SxProps } from '@mui/material';
import type { CheckboxProps as MaterialUICheckboxProps } from '@mui/material/Checkbox';
import MaterialUICheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { ICONS } from '@/constants/icons';

type CustomCheckboxProps = Omit<
  MaterialUICheckboxProps,
  'checkedIcon' | 'color' | 'icon'
>;

const CustomCheckbox = (props: CustomCheckboxProps): JSX.Element => (
  <MaterialUICheckbox
    checkedIcon={<ICONS.ImCheckboxChecked fontSize="2.4rem" />}
    color="default"
    icon={<EmptyCheckboxIcon height="2.4rem" width="2.4rem" />}
    {...props}
  />
);

type CheckboxProps = CustomCheckboxProps & {
  label: string;
  textstyle?: SxProps;
  textwrap?: boolean | undefined;
};

const Checkbox = (props: CheckboxProps): JSX.Element => {
  const { label, textstyle, textwrap = false } = props;

  return (
    <FormControlLabel
      control={<CustomCheckbox {...props} />}
      label={
        <Typography
          fontWeight={500}
          variant="body1"
          maxWidth={600}
          sx={textstyle}
          noWrap={textwrap}
        >
          {label}
        </Typography>
      }
      sx={{ marginLeft: 0 }}
    />
  );
};

export default Checkbox;
