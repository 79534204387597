import Box from '@mui/material/Box';

import Footer from '@/components/Footer';
import Header from '@/components/Header';
import SidebarNavigation from '@components/SidebarNavigation';

import useGetBusiness from '@/api/hooks/useGetBusiness';
import NewLocationSidebar from '@/components/NewLocationSidebar';
import ProgressBarComponent from '@/components/UI/ProgressBar';
import type { NavigationItem } from '@/features/ui/navigation';
import { useBusinessId } from '@/utils/hooks/useBusinessId';

interface WithSidebar {
  hasSidebar: true;
  navitems: NavigationItem[];
}

interface WithoutSidebar {
  hasSidebar?: never;
}

type BaseLayoutProps = {
  children: React.ReactNode;
  centerContent?: boolean;
  hasHeader?: boolean;
  isBlock?: boolean;
  hasProgressBar?: boolean;
} & (WithSidebar | WithoutSidebar);

const BaseLayout = (props: BaseLayoutProps): JSX.Element => {
  const {
    children,
    centerContent,
    hasHeader,
    hasSidebar,
    isBlock,
    hasProgressBar,
  } = props;

  const businessId = useBusinessId();

  const { data: business } = useGetBusiness({ businessId });

  let gridAreas = '';

  if (hasHeader) {
    gridAreas += hasSidebar ? "'header header'" : "'header'";
  }

  gridAreas += hasSidebar ? "'sidebar content'" : "'content'";

  gridAreas += hasSidebar ? "'sidebar footer'" : "'footer'";

  return (
    <Box
      display="grid"
      gridTemplateAreas={gridAreas}
      gridTemplateColumns={hasSidebar ? '34.5rem 1fr' : '1fr'}
      gridTemplateRows={hasHeader ? '10rem 1fr' : '1fr'}
      minHeight="100vh"
    >
      {hasHeader && <Header />}

      {hasSidebar && business?.finishedOnboard ? (
        <NewLocationSidebar navItemList={props.navitems} />
      ) : hasSidebar ? (
        <SidebarNavigation navItemList={props.navitems} />
      ) : null}

      <Box
        component="main"
        gridArea="content"
        margin={centerContent ? '3.6rem auto 0' : undefined}
        width={isBlock ? '100%' : '76.3rem'}
      >
        {hasProgressBar && !business?.finishedOnboard && (
          <ProgressBarComponent />
        )}
        {children}
      </Box>

      <Footer />
    </Box>
  );
};

export default BaseLayout;
