import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

export const StyledLinearProgress = styled(LinearProgress)(() => ({
  width: '100%',
  height: '8px',
  borderRadius: '2px',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#E7F4F7',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: '1px',
    background:
      'linear-gradient(90deg, #37BEDD 25.48%, rgba(6, 46, 55, 0.96) 102.51%);',
  },
}));
