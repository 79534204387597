/* eslint-disable @typescript-eslint/promise-function-async */

import { useAuth0 } from '@auth0/auth0-react';
import { lazy, Suspense, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import {
  BankAccountRoutes,
  BusinessDetailsRoutes,
  BusinessDocumentsRoutes,
  BusinessOperatorDetailsRoutes,
  BusinessOwnershipRoutes,
  FacilitiesLocationsRoutes,
  ReviewAndSignRoutes,
  Router as RouterConstant,
} from '@/constants/router';

import HomePage from '@/pages/Home';

import { facilityRoutes } from './facilities-routes';

import { navItems } from './config';

import { useBusinessId } from '@/utils/hooks/useBusinessId';

import useGetBusinessValidation from '@/api/hooks/useGetBusinessValidation';

import useAuthenticateUser from '@/api/hooks/useAuthenticateUser';
import { ApplicationStatusEnum } from '@/api/hooks/useGetBusinessValidation/type';
import { SyncteraVerificationStatus } from '@/constants/synctera';
import type { SystemUserAuthResponse } from '@/types/user';
import { BankAccountComponents } from '@components/Router/bank-account-routes';
import {
  SystemUsersStatuses,
  SystemUsersValue,
} from '@pages/BankAccount/SystemUsers/__utils__/mock';
import { useBoolean } from 'usehooks-ts';
import FacilitiesLayout from '../Layouts/FacilitiesLayout';
import NotFound from '../NotFound';
import AlertModal from '../UI/AlertModal';
import { analyticsIdentify } from '@/utils/analytics';

// TODO: move this to `routerConfig` file
// TODO: rest of the paths will be progressively updated to not delay integration

const LazyTellUsAboutYourBusiness = lazy(
  () => import('@pages/BusinessDetails/TellUsAboutYourBusiness'),
);

const LazyEligibility = lazy(() => import('@pages/Eligibility'));

const LazyEmailVerification = lazy(() => import('@pages/EmailVerification'));

const LazyEligibilityStatus = lazy(() => import('@pages/EligibilityStatus'));

const LazyOnboardingLayout = lazy(
  () => import('@components/Layouts/OnboardingLayout'),
);

const EligibilityLayout = lazy(
  () => import('@components/Layouts/EligibilityLayout'),
);

const EmailVerificationLayout = lazy(
  () => import('@components/Layouts/EmailVerificationLayout'),
);

const LazyReviewDetails = lazy(() => import('@pages/BusinessDetails/Review'));

const LazyBusinessContact = lazy(
  () => import('@pages/BusinessDetails/BusinessContact'),
);

const LazyBusinessAddress = lazy(
  () => import('@pages/BusinessDetails/BusinessAddress'),
);

const LazyIndividualOwnerInformation = lazy(
  () => import('@pages/BusinessOwnership/IndividualOwnerInformation'),
);

const LazyBusinessOwnerInformation = lazy(
  () => import('@pages/BusinessOwnership/BusinessOwnerInformation'),
);

const LazyBusinessOwnerStart = lazy(
  () => import('@pages/BusinessOwnership/Start'),
);

const LazyBusinessOwnerReview = lazy(
  () => import('@pages/BusinessOwnership/Review'),
);

const LazyBusinessDocuments = lazy(
  () => import('@pages/BusinessDocuments/index'),
);

const LazyBankAccountLayout = lazy(
  () => import('@components/Layouts/BankAccountLayout'),
);

const LazyBusinessOperatorDetails = lazy(
  () => import('@pages/BusinessOperatorDetails'),
);

const LazyLicensedFacilities = lazy(
  () => import('@pages/Facilities/LicensedFacilities'),
);

const LazyLegalNames = lazy(() => import('@pages/Facilities/LegalNames'));

const LazyAddNewFacility = lazy(
  () => import('@pages/Facilities/AddNewFacility'),
);

const LazyLocationRequiredDocs = lazy(
  () => import('@pages/Facilities/Sections/LocationDocs/RequiredDocuments'),
);

const LazySubmitPage = lazy(() => import('@pages/Review&Sign/SubmitPage'));
const LazySubmitSuccessPage = lazy(
  () => import('@pages/Review&Sign/SuccessPage'),
);

const LazyRequiredDocuments = lazy(
  () => import('@pages/BusinessDetails/RequiredDocuments'),
);

function Router(): JSX.Element {
  const {
    value: isBusinessOperator,
    setFalse: setBusinessOperatorFalse,
    setTrue: setBusinessOperatorTrue,
  } = useBoolean(false);

  const { value: isAuthorized, setFalse: setIsAuthorizedFalse } =
    useBoolean(true);

  const [systemUser, setSystemUser] = useState<SystemUserAuthResponse>();

  const navigate = useNavigate();
  const location = useLocation();

  const { isAuthenticated, isLoading, logout, user, loginWithRedirect } =
    useAuth0();

  const isUserAuthed = isAuthenticated && !isLoading;

  const businessId = useBusinessId(isUserAuthed);

  const [showAccountHeader, setShowHeader] = useState(false);

  const { data: businessValidation, refetch } = useGetBusinessValidation(
    businessId ?? '',
    {
      enabled: businessId != null,
    },
  );

  const { data: authenticatedUser } = useAuthenticateUser({
    enabled: isUserAuthed,
    onSuccess(data) {
      setSystemUser(data.SystemUser);
    },
  });

  useEffect(() => {
    if (location.pathname.includes('login')) {
      loginWithRedirect().catch(console.error);
      return;
    }
    if (
      isUserAuthed &&
      location.pathname === '/' &&
      user != null &&
      user.sub !== undefined &&
      user.email !== undefined
    ) {
      analyticsIdentify(user.sub, { userId: user.sub, email: user.email });
    }
    if (isAuthenticated || isLoading) return;

    navigate('/', { replace: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    if (user != null) {
      if (user.email_verified === false) {
        navigate('/verify-email', { replace: true });
      }

      let isAccountApproved = false;
      if (businessValidation != null) {
        const { status: validationStatus } = businessValidation;
        isAccountApproved = validationStatus === ApplicationStatusEnum.APPROVED;
      }

      if (systemUser?.type !== undefined) {
        const isBusinessOperator =
          systemUser.type === SystemUsersValue.BUSINESS_OPERATOR;
        const isKycApproved =
          authenticatedUser?.kycStatus === SyncteraVerificationStatus.ACCEPTED;
        const isSystemUserActive =
          systemUser?.status === SystemUsersStatuses.ACTIVE;
        const isWalletOperator =
          systemUser?.type === SystemUsersValue.WALLET_OPERATOR;

        if (
          isWalletOperator ||
          (isAccountApproved && isBusinessOperator && !isKycApproved) ||
          (isAccountApproved && !isSystemUserActive)
        ) {
          setIsAuthorizedFalse();
        }

        if (systemUser?.type === SystemUsersValue.BUSINESS_OPERATOR)
          setBusinessOperatorTrue();
        else setBusinessOperatorFalse();
      }
    }
  }, [
    authenticatedUser?.kycStatus,
    businessValidation,
    isUserAuthed,
    navigate,
    setBusinessOperatorFalse,
    setBusinessOperatorTrue,
    setIsAuthorizedFalse,
    systemUser,
    user,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const isEligibilityPage = location.pathname.includes('eligibility');
    const isVerifyEmailPage = location.pathname.includes('verify-email');
    const userDoesNotHaveBusiness = authenticatedUser?.businesses?.length === 0;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
    if (!isEligibilityPage && !isVerifyEmailPage && userDoesNotHaveBusiness) {
      navigate('/eligibility');
    }

    if (businessValidation != null && systemUser?.type) {
      const { status: validationStatus } = businessValidation;

      const isAccountApproved =
        validationStatus === ApplicationStatusEnum.APPROVED;

      const isPendingOnboard = [
        ApplicationStatusEnum.ONBOARDING_NOT_FINISHED,
        ApplicationStatusEnum.REVIEW_INFO_REQUESTED,
      ].includes(validationStatus);

      const shouldLockOnSuccessPage = [
        ApplicationStatusEnum.REVIEW_ON_SYNCTERA,
        ApplicationStatusEnum.DENIED,
      ].includes(validationStatus);

      const isSuccessPage =
        location.pathname === RouterConstant.Onboarding.ReviewAndSign.Success;

      const isBankDetailsPage =
        location.pathname === RouterConstant.BankAccount.BankAccountDetails;

      const isDashboard =
        location.pathname === RouterConstant.BankAccount.Dashboard;

      const isSysmteUsersPage =
        location.pathname === RouterConstant.BankAccount.SystemUsers;

      const isReportsPage =
        location.pathname === RouterConstant.BankAccount.Reports;

      const bankingRoutes = Object.values(RouterConstant.BankAccount);

      const facilitiesBusinessActivityRoutes = Object.values(
        RouterConstant.BankAccount.Facilities.BusinessActivity,
      );

      const facilitiesBankingActivityRoutes = Object.values(
        RouterConstant.BankAccount.Facilities.BankingActivity,
      );

      const facilitiesDetailsRoutes = Object.values(
        RouterConstant.BankAccount.Facilities.Details,
      );

      const facilitiesDocumentsRoutes = Object.values(
        RouterConstant.BankAccount.Facilities.Documents,
      );

      const facilitiesLocationReviewRoutes = Object.values(
        RouterConstant.BankAccount.Facilities.LocationReview,
      );

      const facilitiesLocationRoutes = Object.values(
        RouterConstant.BankAccount.FacilitiesLocations,
      );

      const reviewAndSignRoutes = Object.values(
        RouterConstant.Onboarding.ReviewAndSign,
      );

      if (
        isAccountApproved &&
        !isVerifyEmailPage &&
        !bankingRoutes.includes(location.pathname) &&
        !facilitiesBankingActivityRoutes.includes(location.pathname) &&
        !facilitiesLocationRoutes.includes(location.pathname) &&
        !facilitiesBusinessActivityRoutes.includes(location.pathname) &&
        !facilitiesDetailsRoutes.includes(location.pathname) &&
        !facilitiesDocumentsRoutes.includes(location.pathname) &&
        !facilitiesLocationReviewRoutes.includes(location.pathname) &&
        !reviewAndSignRoutes.includes(location.pathname)
      ) {
        if (systemUser?.type === SystemUsersValue.BUSINESS_OPERATOR)
          navigate(RouterConstant.BankAccount.Dashboard);
        else navigate(RouterConstant.BankAccount.SystemUsers);
        return;
      }

      if (isPendingOnboard && bankingRoutes.includes(location.pathname)) {
        navigate(RouterConstant.Onboarding.BusinessDetails.About);
        return;
      }

      if (isSuccessPage) return;

      if (
        isBankDetailsPage ||
        isDashboard ||
        isSysmteUsersPage ||
        isReportsPage
      ) {
        setShowHeader(true);
      } else setShowHeader(false);

      if (shouldLockOnSuccessPage) {
        navigate(RouterConstant.Onboarding.ReviewAndSign.Success);
      } else {
        refetch().catch(console.error);
      }
    }
  });
  return (
    <Suspense>
      {isUserAuthed && !isAuthorized ? (
        <AlertModal
          errorMessage={`You do not have permission to access this Dashboard.`}
          open={true}
          onClose={() => logout({ returnTo: window.location.origin })}
        />
      ) : (
        <Routes>
          <Route path="/" element={<HomePage />} />

          {isUserAuthed && (
            <>
              <Route path="eligibility" element={<EligibilityLayout />}>
                <Route index element={<LazyEligibility />} />
                <Route path="status" element={<LazyEligibilityStatus />} />
              </Route>

              <Route path="verify-email" element={<EmailVerificationLayout />}>
                <Route index element={<LazyEmailVerification />} />
              </Route>

              {facilityRoutes.map((parentFacilityRoute) => {
                const {
                  childRoutes: childFacilityRoutes,
                  component: ParentFacilityComponent,
                  id: parentId,
                  path: parentPath,
                } = parentFacilityRoute;

                return (
                  <Route
                    element={ParentFacilityComponent}
                    key={parentId}
                    path={parentPath}
                  >
                    {childFacilityRoutes.map((childFacilityRoute) => {
                      const {
                        component: ChildFacilityComponent,
                        id: childId,
                        path: childPath,
                      } = childFacilityRoute;

                      return (
                        <Route
                          key={childId}
                          path={childPath}
                          element={ChildFacilityComponent}
                        />
                      );
                    })}
                  </Route>
                );
              })}

              <Route
                path={FacilitiesLocationsRoutes.__BasePath__}
                element={
                  systemUser !== undefined ? (
                    <FacilitiesLayout />
                  ) : (
                    <LazyOnboardingLayout />
                  )
                }
              >
                <Route
                  path={FacilitiesLocationsRoutes.LicensedFacilities}
                  element={<LazyLicensedFacilities />}
                />
                <Route
                  path={FacilitiesLocationsRoutes.LegalNames}
                  element={<LazyLegalNames />}
                />
                <Route
                  path={FacilitiesLocationsRoutes.AddNewFacility}
                  element={<LazyAddNewFacility />}
                />
                {/* <Route
                  path={FacilitiesLocationsRoutes.NamesAreCorrect}
                  element={<LazyNamesAreCorrect />}
                /> */}
                {/* <Route
                  path={FacilitiesLocationsRoutes.ReviewDetails}
                  element={<LazyLocationReviewDetails />}
                /> */}
                <Route
                  path={FacilitiesLocationsRoutes.RequiredDocs}
                  element={<LazyLocationRequiredDocs />}
                />
              </Route>

              <Route
                path={BusinessDetailsRoutes.__BasePath__}
                element={<LazyOnboardingLayout />}
              >
                <Route
                  path={BusinessDetailsRoutes.RequiredDocuments}
                  element={<LazyRequiredDocuments />}
                />
                <Route
                  path={BusinessDetailsRoutes.About}
                  element={<LazyTellUsAboutYourBusiness />}
                />

                <Route
                  path={BusinessDetailsRoutes.Address}
                  element={<LazyBusinessAddress />}
                />

                <Route
                  path={BusinessDetailsRoutes.Contact}
                  element={<LazyBusinessContact />}
                />

                <Route
                  path={BusinessDetailsRoutes.Review}
                  element={<LazyReviewDetails />}
                />
              </Route>

              <Route
                path={BusinessOwnershipRoutes.__BasePath__}
                element={<LazyOnboardingLayout />}
              >
                <Route
                  path={BusinessOwnershipRoutes.IndividualOwner}
                  element={<LazyIndividualOwnerInformation />}
                />

                <Route
                  path={BusinessOwnershipRoutes.BusinessOwner}
                  element={<LazyBusinessOwnerInformation />}
                />

                <Route
                  path={BusinessOwnershipRoutes.Start}
                  element={<LazyBusinessOwnerStart />}
                />

                <Route
                  path={BusinessOwnershipRoutes.ReviewOwners}
                  element={<LazyBusinessOwnerReview />}
                />
              </Route>

              <Route
                path={BusinessDocumentsRoutes.__BasePath__}
                element={<LazyOnboardingLayout />}
              >
                <Route
                  path={BusinessDocumentsRoutes.UploadDocuments}
                  element={<LazyBusinessDocuments />}
                />
              </Route>

              <Route
                path={BusinessOperatorDetailsRoutes.__BasePath__}
                element={<LazyOnboardingLayout />}
              >
                <Route
                  path={BusinessOperatorDetailsRoutes.Details}
                  element={<LazyBusinessOperatorDetails />}
                />
              </Route>

              <Route
                path={ReviewAndSignRoutes.__BasePath__}
                element={<LazyOnboardingLayout />}
              >
                <Route
                  path={ReviewAndSignRoutes.Submit}
                  element={<LazySubmitPage />}
                />

                <Route
                  path={ReviewAndSignRoutes.Success}
                  element={<LazySubmitSuccessPage />}
                />
              </Route>
            </>
          )}

          {isUserAuthed && systemUser !== undefined && (
            <>
              <Route
                path={BankAccountRoutes.__BasePath__}
                element={
                  <LazyBankAccountLayout
                    navList={navItems(systemUser?.type).bankAccount}
                    systemUser={systemUser}
                    showHeader={showAccountHeader}
                  />
                }
              >
                {(
                  Object.keys(BankAccountComponents) as Array<
                    keyof typeof BankAccountComponents
                  >
                )
                  .filter((route) =>
                    isBusinessOperator
                      ? true
                      : route === 'SystemUsers' ||
                        route === 'LocationDetails' ||
                        route === 'BusinessDetails' ||
                        route === 'Reports',
                  )
                  .map((route) => {
                    let prop;
                    if (route === 'SystemUsers') prop = systemUser;
                    return (
                      <Route
                        key={BankAccountComponents[route].id}
                        path={BankAccountComponents[route].path}
                        element={BankAccountComponents[route].component(prop)}
                      />
                    );
                  })}
              </Route>
            </>
          )}

          <Route path="*" element={<NotFound />} />
        </Routes>
      )}
    </Suspense>
  );
}

export default Router;
