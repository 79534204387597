import Grid from '@mui/material/Grid';

import type { Page } from '../../types';

import { ParentPageItem, ParentPageItemWithIcon } from '../index';

interface MainPageListIteratorProps {
  activePage: number;
  pageList: Page[];
}

const MainPageListIterator = (
  props: MainPageListIteratorProps,
): JSX.Element => {
  const { activePage, pageList } = props;

  return (
    <Grid container gap={1.5} maxWidth="fit-content" wrap="wrap">
      {pageList.map((page, index) => {
        const isActive = activePage === index + 1;

        if (index === 0) {
          return (
            <div key={page.id}>
              <ParentPageItem isActive={isActive} text={page.name} />
            </div>
          );
        }

        return (
          <ParentPageItemWithIcon
            isActive={isActive}
            key={page.id}
            text={page.name}
          />
        );
      })}
    </Grid>
  );
};

export default MainPageListIterator;
