import { nanoid } from 'nanoid';

interface Option<TL, FL, TV, FV> {
  id: string;
  label: TL | FL;
  value: TV | FV;
}

interface GetBooleanOptionListArgs<TL, FL, TV, FV> {
  labels: readonly [TL, FL];
  values: readonly [TV, FV];
}

export const getRadioOptionList = <
  TL extends string,
  FL extends string,
  TV extends string,
  FV extends string,
>(
  args: GetBooleanOptionListArgs<TL, FL, TV, FV>,
): Array<Option<TL, FL, TV, FV>> => {
  const { labels, values } = args;

  const [trueLabel, falseLabel] = labels;

  const [trueValue, falseValue] = values;

  return new Array<null>(2)
    .fill(null)
    .map<Option<TL, FL, TV, FV>>((_, index) => {
      const isFirstIndex = index === 0;

      const parsedBooleanValue = isFirstIndex ? trueValue : falseValue;

      return {
        id: nanoid(),
        label: isFirstIndex ? trueLabel : falseLabel,
        value: parsedBooleanValue,
      };
    });
};
