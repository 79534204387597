import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import { matchPath, Outlet, useLocation } from 'react-router-dom';

import useGetBusiness from '@/api/hooks/useGetBusiness';

import BaseLayout from '@/components/Layouts/BaseLayout';
import Pagination from '@/components/Pagination';

import { CSS_VARS } from '@/constants/css-vars';
import { navigationItemList } from '@/constants/nav-items/onboarding';

import { useFacilitySectionData } from '@/pages/Facilities/hooks/useFacilitySectionData';

import { useBusinessId } from '@/utils/hooks/useBusinessId';

import { newFacilityItems, pageList } from './data';

import useGetFacilities from '@/api/hooks/useGetFacilities';
import LoadingComponent from '@/components/UI/LoadingComponent';
import { Router as RouterConstant } from '@/constants/router';

const FacilitiesLayout = (): JSX.Element => {
  const { pathname } = useLocation();

  const businessId = useBusinessId();

  const { data: business } = useGetBusiness({ businessId });

  const { description, facilityName, title, pageNumber } =
    useFacilitySectionData();

  useGetFacilities(businessId ?? '', false, {
    enabled: businessId != null,
  });
  const facilitiesLocationRoutes = Object.values(
    RouterConstant.BankAccount.FacilitiesLocations,
  );

  const isOnLocationDetails = facilitiesLocationRoutes.includes(
    location.pathname,
  );

  const activePageIndex = pageList.findIndex((page) =>
    matchPath(page.id + '/*', pathname),
  );

  const applicationProgress = business?.businessApplicationProgress;

  const normalizedNavigationItemList = useMemo(() => {
    return navigationItemList.map((navigationItem) => {
      const { id } = navigationItem;

      const { status: progressStatus } =
        applicationProgress?.sections.find((section) => section.id === id) ??
        {};

      return {
        ...navigationItem,
        checkBox: true,
        isDisabled: false,
        progressStatus,
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(applicationProgress?.sections)]);

  return business == null ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        minHeight: '50vh',
      }}
    >
      <LoadingComponent />
    </div>
  ) : (
    <BaseLayout
      centerContent
      hasHeader
      hasSidebar
      hasProgressBar={!business?.finishedOnboard}
      navitems={
        business?.finishedOnboard
          ? newFacilityItems
          : normalizedNavigationItemList
      }
    >
      {typeof facilityName === 'string' && facilityName !== '' && (
        <Grid
          bgcolor={`var(${CSS_VARS.Colors.BrandBlue[100]})`}
          borderRadius="0.8rem"
          margin="0.7rem auto 3rem"
          maxWidth="100.9rem"
          padding="1.8rem 0"
        >
          <Typography align="center" fontWeight={500} variant="body1">
            Entering information for {facilityName}.
          </Typography>
        </Grid>
      )}

      <Box maxWidth="76.3rem" margin="3.6rem auto 0">
        {isOnLocationDetails ? null : business.finishedOnboard ? (
          <Pagination
            activeMainPage={activePageIndex + 1}
            currentChildSection={activePageIndex + 1}
            mainPageList={pageList}
            totalChildSections={5}
          />
        ) : (
          <Pagination
            currentChildSection={pageNumber}
            activeMainPage={activePageIndex + 1}
            totalChildSections={24}
            mainPageList={pageList}
          />
        )}

        {typeof title === 'string' && (
          <Typography gutterBottom variant="h2">
            {title}
          </Typography>
        )}

        {typeof description === 'string' && (
          <Typography fontWeight={500} marginBottom="4.8rem" variant="body1">
            {description}
          </Typography>
        )}

        <Outlet />
      </Box>
    </BaseLayout>
  );
};

export default FacilitiesLayout;
