import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import getBusinessValidation from './getBusinessValidation';

import type { GetBusinessApplicationStatusResponse } from './type';

const useGetBusinessValidation = (
  businessId: string,
  queryOptions?: UseQueryOptions<GetBusinessApplicationStatusResponse, Error>,
): UseQueryResult<GetBusinessApplicationStatusResponse, Error> =>
  useQuery<GetBusinessApplicationStatusResponse, Error>(
    ['getBusinessValidation'],
    // eslint-disable-next-line @typescript-eslint/promise-function-async
    () => getBusinessValidation(businessId),
    queryOptions,
  );

export default useGetBusinessValidation;
