import Router from '@components/Router';
import { useEffect } from 'react';
import IdleTimer from '@/utils/idleTimer';
import { useAuth0 } from '@auth0/auth0-react';
import { StorageKeys } from '@/constants/misc';
import { useSentry } from '@/utils/useSentry';

function App(): JSX.Element {
  useSentry();
  const { isAuthenticated, logout } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated) return;

    const timer = new IdleTimer({
      timeout: 60 * 15,
      onTimeout: () => {
        localStorage.removeItem(StorageKeys.Token);
        localStorage.removeItem('facilities-storage');
        localStorage.removeItem('create-new-facility');
        localStorage.removeItem('selected-facility');

        logout({ returnTo: window.location.origin });
      },
    });
    return () => {
      timer.cleanUp();
    };
  }, [isAuthenticated, logout]);
  return <Router />;
}

export default App;
