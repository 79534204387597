import List from '@mui/material/List';
import { memo } from 'react';

import type { NavigationItem } from '@/features/ui/navigation';
import { useNavigationState } from '@/features/ui/navigation';

import NavigationButton from '../NavigationButton';

interface NavIteratorProps {
  list: NavigationItem[];
}

const NavIterator = memo((props: NavIteratorProps) => {
  const { list } = props;

  const setCurrentStepId = useNavigationState(
    (state) => state.setCurrentStepId,
  );

  const handleNavigationButtonClick = (
    navButtonId: NavigationItem['id'],
  ): void => {
    setCurrentStepId(navButtonId);
  };

  return (
    <nav>
      <List disablePadding>
        {list.map((listItem) => (
          <NavigationButton
            {...listItem}
            key={listItem.id}
            onClick={handleNavigationButtonClick}
          />
        ))}
      </List>
    </nav>
  );
});

if (import.meta.env.DEV) {
  NavIterator.displayName = 'NavIterator';
}

export default NavIterator;
