import Typography from '@mui/material/Typography';

import { CSS_VARS } from '@/constants/css-vars';

type Status = 'active' | 'notActive';

interface ParentPageItemProps {
  text: string;
  isActive: boolean;
}

const TEXT_COLORS: Record<Status, string> = {
  active: CSS_VARS.Colors.BlackBlue,
  notActive: CSS_VARS.Colors.Gray[400],
};

const ParentPageItem = (props: ParentPageItemProps): JSX.Element => {
  const { text, isActive } = props;

  const textColor = TEXT_COLORS[isActive ? 'active' : 'notActive'];

  return (
    <Typography
      color={`var(${textColor})`}
      fontWeight={isActive ? 700 : 500}
      variant="body2"
    >
      {text}
    </Typography>
  );
};

export default ParentPageItem;
