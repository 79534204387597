import { useAuth0 } from '@auth0/auth0-react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { memo } from 'react';
import * as Sentry from '@sentry/react';
import { CSS_VARS } from '@/constants/css-vars';
import { StorageKeys } from '@/constants/misc';

const typographyProps = {
  color: `var(${CSS_VARS.Colors.BrandBlue[400]})`,
  fontWeight: 700,
};

const Header = memo(() => {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();

  const handleAuth = async (): Promise<void> => {
    try {
      if (isAuthenticated) {
        localStorage.removeItem(StorageKeys.Token);
        localStorage.removeItem('facilities-storage');
        localStorage.removeItem('create-new-facility');
        localStorage.removeItem('selected-facility');

        logout({ returnTo: window.location.origin });

        return;
      }
      localStorage.removeItem('selected-facility');
      await loginWithRedirect();
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  };

  return (
    <Box
      border={`0.1rem solid var(${CSS_VARS.Colors.Gray[100]})`}
      component="header"
      gridArea="header"
      padding="2.4rem 4.8rem"
    >
      <Grid
        alignItems="center"
        component="nav"
        container
        justifyContent="space-between"
      >
        <img
          src="https://storage.googleapis.com/paybotic-banking-fe-develop/final_logo.png"
          alt="Paybotic logo"
          height={49}
        />

        <Box display="flex" alignItems="center">
          {!!user && (
            <Typography {...typographyProps}>{user.email} |</Typography>
          )}
          <Button variant="text" onClick={handleAuth}>
            <Typography {...typographyProps}>
              {isAuthenticated ? 'Log out' : 'Log in'}
            </Typography>
          </Button>
        </Box>
      </Grid>
    </Box>
  );
});

if (import.meta.env.DEV) {
  Header.displayName = 'Header';
}

export default Header;
