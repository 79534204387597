import Typography from '@mui/material/Typography';

interface ColumnHeaderProps {
  children: React.ReactNode;
}

const ColumnHeader = (props: ColumnHeaderProps): JSX.Element => {
  const { children } = props;

  return (
    <Typography
      color="#9a9a9d"
      fontSize={12}
      fontWeight={700}
      noWrap
      textTransform="uppercase"
    >
      {children}
    </Typography>
  );
};

export default ColumnHeader;
