import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface FacilitiesState {
  facilityId: string | null;
  facilityName: string | null;
  sectionDescription: string | null;
  sectionTitle: string | null;
  pageNumber: number | null;
  updateFacilityId: (facilityId: string | null) => void;
  updateFacilityName: (facilityName: string | null) => void;
  updateSectionDescription: (sectionDescription: string | null) => void;
  updateSectionTitle: (sectionTitle: string | null) => void;
  updatePageNumber: (pageNumber: number | null) => void;
}

export const useFacilitiesState = create<FacilitiesState>()(
  devtools(
    persist(
      (set) => ({
        facilityId: null,
        facilityName: null,
        sectionDescription: null,
        sectionTitle: null,
        pageNumber: null,
        updateFacilityId: (facilityId) =>
          set({ facilityId }, false, 'facilities/updateFacilityId'),
        updateFacilityName: (facilityName) =>
          set({ facilityName }, false, 'facilities/updateFacilityName'),
        updateSectionDescription: (sectionDescription) =>
          set(
            () => ({ sectionDescription }),
            false,
            'facilities/updateSectionDescription',
          ),
        updateSectionTitle: (sectionTitle) =>
          set({ sectionTitle }, false, 'facilities/updateSectionTitle'),
        updatePageNumber: (pageNumber) =>
          set({ pageNumber }, false, 'facilities/updatePageNumber'),
      }),
      {
        name: 'facilities-storage',
      },
    ),
  ),
);
