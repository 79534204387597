interface UseParseFileNameOutput {
  extension: string;
  name: string;
}

export const useParseFileName = (fileName: string): UseParseFileNameOutput => {
  const fileNameLength = fileName.length;

  const indexOfLastDotInFileName = fileName.lastIndexOf('.') + 1;

  const fileExtension = fileName.substring(
    indexOfLastDotInFileName,
    fileNameLength,
  );

  const restOfFileName = fileName.substring(0, indexOfLastDotInFileName);

  return {
    extension: fileExtension,
    name: restOfFileName,
  };
};
