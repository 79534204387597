import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type {
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
} from 'react-hook-form';

import { CSS_VARS } from '@/constants/css-vars';

import InputMask from '@/components/Forms/InputMask';
import type { AmountsType, FormSchema, TransactionsType } from './types';

interface InputFieldProps {
  amount: AmountsType;
  errors: FieldErrors<FormSchema>;
  register: UseFormRegister<FormSchema>;
  title: string;
  transactions: TransactionsType;
  greyBackground?: boolean;
  getValues: UseFormGetValues<FormSchema>;
  disabled?: boolean;
  disabledTransaction?: boolean;
  errorFlag?: boolean;
  errorFlagTransaction?: boolean;
}

function InputField(props: InputFieldProps): JSX.Element {
  const {
    amount,
    errors,
    register,
    title,
    transactions,
    greyBackground = false,
    getValues,
    disabled = false,
    disabledTransaction = false,
    errorFlag = false,
    errorFlagTransaction = false,
  } = props;

  return (
    <Grid
      alignItems="flex-start"
      bgcolor={`var(${
        greyBackground ? CSS_VARS.Colors.Gray[100] : CSS_VARS.Colors.White
      })`}
      container
      gap="25px"
      padding="10px"
      flexWrap="nowrap"
      width="764px"
      height="80px"
    >
      <Grid alignItems="center" display="flex">
        <Typography
          color={`var(${CSS_VARS.Colors.BlackBlue})`}
          fontWeight={700}
          fontSize="18px"
          lineHeight="28px"
          width="246px"
          alignItems="flex-start"
        >
          {title}
        </Typography>
      </Grid>

      <Grid
        container
        gap="10px"
        padding="0 0.6rem 0 0"
        justifyContent="flex-end"
      >
        <InputMask
          {...register(amount)}
          type="money"
          width="246px"
          fieldErrors={errors[amount]}
          helperText={errors[amount]?.message}
          showHelperText
          value={getValues(amount)}
          disabled={disabled}
          errorFlag={errorFlag}
        />

        <InputMask
          {...register(transactions)}
          fieldErrors={errors[transactions]}
          helperText={errors[transactions]?.message}
          showHelperText
          width="173px"
          type="number"
          value={getValues(transactions)}
          disabled={disabledTransaction}
          errorFlag={errorFlagTransaction}
        />
      </Grid>
    </Grid>
  );
}

export default InputField;
