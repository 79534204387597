import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';

import updateBusinessApplicationProgress, {
  UpdateBusinessApplicationProgress,
} from './updateBusinessApplicationProgress';

const useUpdateBusinessApplicationProgress = (): UseMutationResult<
  UpdateBusinessApplicationProgress,
  Error,
  UpdateBusinessApplicationProgress
> =>
  useMutation<
    UpdateBusinessApplicationProgress,
    Error,
    UpdateBusinessApplicationProgress
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
  >(updateBusinessApplicationProgress);
export default useUpdateBusinessApplicationProgress;
