import React, { memo } from 'react';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { styled } from '@mui/material';

interface FooterItemProps {
  icon: React.ReactNode;
  text: string;
  anchorType?: 'tel' | 'mailto';
}

const StyledListItem = styled(ListItem)({
  padding: '1rem 2rem',
});

const FooterItem = memo((props: FooterItemProps): JSX.Element => {
  const { icon, text, anchorType } = props;

  return (
    <StyledListItem>
      <Grid alignItems="center" container gap={1} wrap="nowrap">
        {icon}

        <ListItemText disableTypography>
          <Typography fontWeight={500} noWrap variant="body1">
            {anchorType != null ? (
              <Link
                href={`${anchorType}:${text}`}
                underline="none"
                color="inherit"
              >
                {text}
              </Link>
            ) : (
              text
            )}
          </Typography>
        </ListItemText>
      </Grid>
    </StyledListItem>
  );
});

if (import.meta.env.DEV) {
  FooterItem.displayName = 'FooterItem';
}

export default FooterItem;
