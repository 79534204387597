import type { Business } from '@/types/business';
import type { SystemUserAuthResponse, User } from '@/types/user';

import fetcher from '@/utils/fetcher';

// eslint-disable-next-line @typescript-eslint/promise-function-async
const getAuthenticatedUser = (): Promise<
  User & { businesses: Business[] } & { SystemUser: SystemUserAuthResponse }
> =>
  fetcher<
    User & { businesses: Business[] } & { SystemUser: SystemUserAuthResponse }
  >({
    endpoint: '/users/authenticate',
  });

export default getAuthenticatedUser;
