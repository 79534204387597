import * as z from 'zod';

const envSchema = z.object({
  DEV: z.boolean(),
  VITE_API_URL: z.string().min(1),
  VITE_AUTH0_AUDIENCE: z.string().min(1),
  VITE_AUTH0_CLIENT_ID: z.string().min(1),
  VITE_AUTH0_DOMAIN: z.string().min(1),
  VITE_SEGMENT_WRITE_KEY: z.string().min(1),
  VITE_VERSION: z.string().min(1),
  VITE_SENTRY_DSN: z.string().optional(),
  VITE_SENTRY_AUTH_TOKEN: z.string().optional(),
  VITE_DEBUG: z.string().optional(),
  VITE_ENVIRONMENT: z.string().optional(),
});

const parsedEnv = envSchema.parse(import.meta.env);

export const {
  DEV: IS_DEV,
  VITE_API_URL: API_URL,
  VITE_AUTH0_AUDIENCE: AUTH0_AUDIENCE,
  VITE_AUTH0_CLIENT_ID: AUTH0_CLIENT_ID,
  VITE_AUTH0_DOMAIN: AUTH0_DOMAIN,
  VITE_SEGMENT_WRITE_KEY: SEGMENT_WRITE_KEY,
  VITE_VERSION: VERSION,
  VITE_SENTRY_DSN: SENTRY,
  VITE_SENTRY_AUTH_TOKEN: SENTRY_TOKEN,
  VITE_DEBUG: DEBUG,
  VITE_ENVIRONMENT: ENVIRONMENT,
} = parsedEnv;
