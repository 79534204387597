import { Router } from '@/constants/router';

import { NavigationItem, navigationStep } from '@/features/ui/navigation';

export const navigationItemList: NavigationItem[] = [
  {
    id: navigationStep.BusinessDetails,
    path: Router.Onboarding.BusinessDetails.About,
    text: 'Business Details',
  },
  {
    id: navigationStep.BusinessOperatorDetails,
    path: Router.Onboarding.BusinessOperatorDetails.Details,
    text: 'Business Operator Details',
  },
  {
    id: navigationStep.BusinessOwnership,
    path: Router.Onboarding.BusinessOwnership.Start,
    text: 'Business Ownership',
  },
  {
    id: navigationStep.BusinessDocuments,
    path: Router.Onboarding.BusinessDocuments.Upload,
    text: 'Business Documents',
  },
  {
    id: navigationStep.Facilities,
    path: Router.Onboarding.FacilitiesLocations.LicensedFacilities,
    text: 'Facilities',
  },
  {
    id: navigationStep.ReviewAndSign,
    path: Router.Onboarding.ReviewAndSign.Submit,
    text: 'Review & Sign',
  },
];
