import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';

import type { BusinessDocumentType } from '@/types/business';

import type {
  UpdateBusinessDocument,
  UpdateBusinessDocumentVariables,
} from './type';

import updateBusinessDocument from './updateBusinessDocument';

const useUpdateBusinessDocument = <
  DocumentType extends BusinessDocumentType = BusinessDocumentType,
>(): UseMutationResult<
  UpdateBusinessDocument<DocumentType>,
  Error,
  UpdateBusinessDocumentVariables
> =>
  useMutation<
    UpdateBusinessDocument<DocumentType>,
    Error,
    UpdateBusinessDocumentVariables
  >(updateBusinessDocument);

export default useUpdateBusinessDocument;
