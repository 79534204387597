import { CSS_VARS } from '@/constants/css-vars';
import { ICONS } from '@/constants/icons';

import type { InputStatus } from './types';

const ICON_SIZE = 12;

export const inputStatusColor: Record<InputStatus, string> = {
  Error: CSS_VARS.Colors.Error.Dark,
  Info: CSS_VARS.Colors.BrandBlue[400],
  Success: CSS_VARS.Colors.Success.Dark,
};

export const inputStatusIcon: Record<InputStatus, JSX.Element> = {
  Error: (
    <ICONS.Close color={`var(${inputStatusColor.Error})`} size={ICON_SIZE} />
  ),
  Info: <ICONS.Info color={`var(${inputStatusColor.Info})`} size={ICON_SIZE} />,
  Success: (
    <ICONS.CheckmarkIcon
      color={`var(${inputStatusColor.Success})`}
      size={ICON_SIZE}
    />
  ),
};

export const getInputStatus = (invalid?: boolean): InputStatus => {
  if (invalid == null) return 'Info';

  return invalid ? 'Error' : 'Success';
};

export const isInputInvalid = <FieldErrors extends Record<string, unknown>>(
  formFields?: FieldErrors,
): boolean | undefined => {
  if (formFields == null) return;

  return Boolean(formFields);
};
