import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import type { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { forwardRef } from 'react';

import { CSS_VARS } from '@/constants/css-vars';

import { selectDisplayStyles, StyledHelperTextContentWrapper } from './styles';

import type { DropdownProps, OptionValue } from './types';

import { getInputStatus, isInvalid, statusColor, statusIcon } from './utils';

const ITEM_HEIGHT = 48;

const ITEM_PADDING_TOP = 8;

const MENU_PROPS: Partial<MenuProps> = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const SELECT_DISPLAY_PROPS: React.HTMLAttributes<HTMLDivElement> = {
  style: selectDisplayStyles,
};

const InnerInputDropdown = <
  OV extends string,
  FieldErrors extends Record<string, unknown> = Record<string, unknown>,
>(
  props: DropdownProps<OV, FieldErrors> & { errorFlag?: boolean },
  ref: React.ForwardedRef<HTMLDivElement>,
): JSX.Element => {
  const {
    disabled,
    endAdornment,
    fieldErrors,
    helperText,
    hideLabel = false,
    label,
    name,
    noNoneOption = false,
    options,
    placeholder,
    required = false,
    showHelperText = false,
    noHelperIcon = false,
    startAdornment,
    width,
    errorFlag = false,
    ...restOfProps
  } = props;

  const describedBy = `${name}-helper-text`;

  const labelBy = `${name}-label`;

  const isInputInvalid = isInvalid(fieldErrors);

  const status = getInputStatus(isInputInvalid);

  return (
    <div style={{ width: width ?? '20rem' }}>
      {!hideLabel && (
        <InputLabel
          disabled={disabled}
          id={labelBy}
          required={required}
          sx={{
            color: required ? `var(${CSS_VARS.Colors.Error.Dark})` : 'inherit',
          }}
        >
          <Typography display="inline" fontWeight={700} variant="body2">
            {label}
          </Typography>
        </InputLabel>
      )}

      <Select<OptionValue<OV>>
        aria-describedby={describedBy}
        disabled={disabled}
        displayEmpty
        endAdornment={endAdornment}
        error={isInputInvalid ?? errorFlag}
        fullWidth
        id={name}
        inputRef={ref}
        labelId={labelBy}
        MenuProps={MENU_PROPS}
        name={name}
        required={required}
        SelectDisplayProps={SELECT_DISPLAY_PROPS}
        startAdornment={startAdornment}
        {...restOfProps}
      >
        {noNoneOption ? null : (
          <MenuItem
            sx={{ color: `var(${CSS_VARS.Colors.BlackBlue})`, opacity: 0.4 }}
            value=""
          >
            {placeholder ?? 'None'}
          </MenuItem>
        )}

        {options.map((option, index) => {
          if (typeof option === 'object' && 'id' in option) {
            return (
              <MenuItem key={option.id} value={option.value}>
                {option.label}
              </MenuItem>
            );
          }

          return (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </Select>

      {showHelperText && (
        <FormHelperText id={describedBy}>
          {helperText == null ? (
            ' '
          ) : !noHelperIcon ? (
            <StyledHelperTextContentWrapper
              style={{ color: `var(${statusColor[status]})` }}
            >
              {statusIcon[status]}&nbsp;{helperText}
            </StyledHelperTextContentWrapper>
          ) : (
            <>{helperText}</>
          )}
        </FormHelperText>
      )}
    </div>
  );
};

const InputDropdown = forwardRef(InnerInputDropdown) as <
  OV extends string,
  FieldErrors extends Record<string, unknown> = Record<string, unknown>,
>(
  props: DropdownProps<OV, FieldErrors> & { errorFlag?: boolean },
  ref: React.ForwardedRef<HTMLDivElement>,
) => JSX.Element;

export default InputDropdown;
