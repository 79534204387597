import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { memo } from 'react';

import { CSS_VARS } from '@/constants/css-vars';

import { Metadata } from '../SidebarNavigation/components';
import LocationNavIterator from './Components/LocationNavIterator';
import type { LocationNavItem } from './data';

interface NewLocationSidebarProps {
  navItemList: LocationNavItem[];
}

const NewLocationSidebar = memo((props: NewLocationSidebarProps) => {
  const { navItemList } = props;

  return (
    <Box
      borderRight={`0.1rem solid var(${CSS_VARS.Colors.Gray[100]})`}
      component="aside"
      gridArea="sidebar"
      height="100%"
      padding="2rem 2rem 0rem"
      paddingLeft={0}
    >
      <Grid
        container
        flexDirection="column"
        height="inherit"
        justifyContent="space-between"
      >
        <LocationNavIterator list={navItemList} />

        <Metadata />
      </Grid>
    </Box>
  );
});

if (import.meta.env.DEV) {
  NewLocationSidebar.displayName = 'NewLocationSidebar;';
}

export default NewLocationSidebar;
