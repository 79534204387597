import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { CSS_VARS } from '@/constants/css-vars';
import { ICONS } from '@/constants/icons';

import type { BusinessDocumentType } from '@/types/business';

import { useParseFileName } from '@/utils/hooks/useParseFileName';

interface UploadDocumentsProps<DT extends BusinessDocumentType> {
  documentType: DT;
  label: string;
  disabled?: boolean;
  documentId?: string;
  errorMessage?: string | null;
  fileName?: string;
  subtitle?: string[];
  onFileUpdate?: (
    documentType: DT,
    file: React.ChangeEvent<HTMLInputElement>,
    documentId: string,
  ) => void;
  onFileUpload?: (
    documentType: DT,
    file: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  required?: boolean;
}

const UploadDocuments = <
  DocumentType extends BusinessDocumentType = BusinessDocumentType,
>(
  props: UploadDocumentsProps<DocumentType>,
): JSX.Element => {
  const {
    documentType,
    label,
    disabled = false,
    documentId,
    fileName,
    subtitle,
    onFileUpdate,
    onFileUpload,
    errorMessage,
    required = false,
  } = props;

  const parsedFileName = useParseFileName(fileName ?? '');

  const hasDocumentId = typeof documentId === 'string';

  const Icon = hasDocumentId ? ICONS.BiCheckCircle : ICONS.BiRadioCircle;

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onFileUpload?.(documentType, event);
  };

  const handleUpdate = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (documentId == null) return;
    onFileUpdate?.(documentType, event, documentId);
  };

  return (
    <Box
      border="0.1rem solid #e4e4e4"
      borderRadius="0.8rem"
      marginTop="2rem"
      padding="2rem 3rem"
      width="100%"
    >
      <Grid
        alignItems="center"
        container
        gap="1rem"
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Grid
          alignItems="center"
          wrap="nowrap"
          container
          gap="1rem"
          width="fit-content"
        >
          <Icon
            color={
              hasDocumentId
                ? `var(${CSS_VARS.Colors.Success.Dark})`
                : `var(${CSS_VARS.Colors.Gray[300]})`
            }
            fontSize="2.7rem"
          />

          <Typography fontWeight={900} noWrap variant="h5">
            {label}
            {required && (
              <span style={{ color: `var(${CSS_VARS.Colors.Error.Dark})` }}>
                *
              </span>
            )}
          </Typography>
        </Grid>

        {fileName != null && (
          <Grid container>
            <Typography
              color={`var(${CSS_VARS.Colors.Gray[400]})`}
              maxWidth="11rem"
              noWrap
              variant="subtitle1"
            >
              {parsedFileName.name}
            </Typography>

            <Typography
              color={`var(${CSS_VARS.Colors.Gray[400]})`}
              variant="subtitle1"
            >
              {parsedFileName.extension}
            </Typography>
          </Grid>
        )}
        <Button
          component="label"
          disabled={disabled}
          size="large"
          variant={documentId == null ? 'contained' : 'outlined'}
          sx={{
            backgroundColor: documentId == null ? '#254D73' : 'white',
            color: '#9a9a9d',
            fontSize: '1.8rem',
            fontWeight: 700,
            marginLeft: '1rem',
            minWidth: 81,
          }}
        >
          <Typography
            color={`var(${
              documentId == null
                ? CSS_VARS.Colors.White
                : CSS_VARS.Colors.BlackBlue
            })`}
            fontWeight={700}
            variant="body1"
          >
            {documentId == null ? 'Upload' : 'Update'}
          </Typography>
          <input
            accept="image/*,.pdf,.zip"
            hidden
            multiple
            type="file"
            onChange={documentId == null ? handleUpload : handleUpdate}
          />
        </Button>
      </Grid>

      <Grid alignItems="center" container>
        <Grid
          direction="column"
          alignItems="flex-start"
          container
          marginLeft="4rem"
        >
          {subtitle?.map((subtitle) => (
            <Typography
              color={`var(${CSS_VARS.Colors.Gray[400]})`}
              key={subtitle}
              variant="subtitle1"
            >
              {subtitle}
            </Typography>
          ))}
        </Grid>

        {errorMessage != null && (
          <Grid alignItems="center" container marginLeft="3rem">
            <Typography
              color={`var(${CSS_VARS.Colors.Error.Dark})`}
              fontWeight={700}
              variant="h6"
            >
              {errorMessage}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
export default UploadDocuments;
