import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemButton, {
  listItemButtonClasses,
} from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import { CSS_VARS } from '@/constants/css-vars';

import type { LocationNavItem } from '../../data';

type LocationNavButtonProps = LocationNavItem & {
  isSelected?: boolean;
  isDisabled?: boolean;
};

const LocationNavButton = (props: LocationNavButtonProps): JSX.Element => {
  const { path, text, isDisabled = false, isSelected = false } = props;

  const textColor = isSelected
    ? CSS_VARS.Colors.BrandBlue[400]
    : CSS_VARS.Colors.Gray[400];

  return (
    <ListItem disableGutters disablePadding>
      <Link
        aria-disabled={isDisabled}
        style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}
        to={path}
      >
        <ListItemButton
          disabled={isDisabled}
          disableGutters
          selected={isSelected}
          sx={{
            borderRadius: '0 5rem 5rem 0',
            padding: '1rem 30px 1rem 0',
            paddingLeft: '3.5rem',
            [`&.${listItemButtonClasses.selected},
              &.${listItemButtonClasses.selected}:hover`]: {
              backgroundColor: `var(${CSS_VARS.Colors.BrandBlue[100]})`,
            },
          }}
        >
          <Grid alignItems="center" container gap={2}>
            <ListItemText disableTypography>
              <Typography
                color={`var(${textColor})`}
                fontWeight={isSelected != null ? 700 : 500}
                noWrap
                variant="h5"
              >
                {text}
              </Typography>
            </ListItemText>
          </Grid>
        </ListItemButton>
      </Link>
    </ListItem>
  );
};

export default LocationNavButton;
