import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import getApplicationFeedbacks from './getApplicationFeedbacks';
import type { GetApplicatonFeedbacks } from './types';

const useGetApplicationFeedbacks = (
  facilityId: string,
  queryOptions?: UseQueryOptions<GetApplicatonFeedbacks[], Error>,
): UseQueryResult<GetApplicatonFeedbacks[], Error> => {
  return useQuery<GetApplicatonFeedbacks[], Error>(
    ['getUserFeedbacks'],
    // eslint-disable-next-line @typescript-eslint/promise-function-async
    () => getApplicationFeedbacks(facilityId),
    queryOptions,
  );
};

export default useGetApplicationFeedbacks;
