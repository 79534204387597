import { patternFormatter } from 'react-number-format';

export const PARSED_BOOLEAN_TUPPLE_LABELS = ['Yes', 'No'] as const;

export const PARSED_BOOLEAN_TUPPLE_VALUES = ['true', 'false'] as const;

export enum StorageKeys {
  Token = 'user.token',
}

export const SYNCTERA_ROLES = {
  managingPerson: {
    label: 'Controller',
    id: 'MANAGING_PERSON_OF',
  },
  owner: {
    label: 'Owner',
    id: 'BENEFICIAL_OWNER_OF',
  },
} as const;

export const SyncteraRoleList = [
  SYNCTERA_ROLES.managingPerson.id,
  SYNCTERA_ROLES.owner.id,
] as const;

export const LEGAL_DOCUMENTS = {
  termsPlatformAndMerchant: 'https://financial.paybotic.com/commercial/tcs/',
  cardHolderAgreement:
    'https://financial.paybotic.com/commercial/merchant-cardholder-agreement/',
  esignConsentDisclosure:
    'https://financial.paybotic.com/commercial/e-sign-consent-disclosure/',
  patrioticAct:
    'https://financial.paybotic.com/commercial/usa-patriot-act-kyc-disclosure/',
  achAuthorization:
    'https://financial.paybotic.com/commercial/ach-authorization-agreement-disclosure/',
  privacyCompliance:
    'https://storage.googleapis.com/paybotic-banking-fe-develop/Privacy%20Policy.pdf',
  terms:
    'https://storage.googleapis.com/paybotic-banking-fe-develop/Terms%20and%20Conditions',
  privacyNotice: 'https://financial.paybotic.com/commercial/privacy-notice/',
  regCCDisclosure:
    'https://financial.paybotic.com/commercial/reg-cc-disclosure/',
};

export const CASH_PICKUP_LINK =
  'https://www.payboticfinancial.com/cash-pick-up-request-form/';

export const DATE_FORMAT = 'MM/DD/YYYY';

export const FIXED_COUNTRY = 'US';

export const PAYBOTIC_INFO = {
  email: 'support@payboticfinancial.com',
  phone: '8334207300',
};

const baseFormatter = (value: string, format: string): string =>
  patternFormatter(value, { format });

export const EIN_FORMAT = '##-#######';
export const ENCRYPTED_EIN_FORMAT = '**-***####';

export const EIN_FORMATTER = (value: string, isEncrypted = false): string =>
  baseFormatter(value, isEncrypted ? ENCRYPTED_EIN_FORMAT : EIN_FORMAT);

export const SSN_FORMAT = '###-##-####';
export const ENCRYPTED_SSN_FORMAT = '***-**-####';

export const SSN_FORMATTER = (value: string, isEncrypted = false): string =>
  baseFormatter(value, isEncrypted ? ENCRYPTED_SSN_FORMAT : SSN_FORMAT);

export const US_PHONE_FORMAT = '(###) ###-####';
export const US_PHONE_FORMAT_WITH_PREFIX = '+# (###) ###-####';

export const PHONE_FORMATTER = (value: string): string =>
  baseFormatter(value, US_PHONE_FORMAT);

export const PHONE_FORMATTER_WITH_PREFIX = (value: string): string =>
  baseFormatter(value, US_PHONE_FORMAT_WITH_PREFIX);

export const FORMAT_FROM_SYNCTERA_CURRENCY_VALUE = (value: number): number =>
  value / 100;

export const getDigitsInString = (str: string): string =>
  str.replace(/\D*/g, '');

export const FORMAT_TO_SYNCTERA_CURRENCY_VALUE = (value: string): number => {
  const amount = value.replace(/,/g, '');
  const cents = Math.round(parseFloat(amount) * 100);
  return cents;
};

export const PAYBOTIC_CONTACT_PHONE = {
  formatted: PHONE_FORMATTER(PAYBOTIC_INFO.phone),
  formattedWithPrefix: PHONE_FORMATTER_WITH_PREFIX(`1${PAYBOTIC_INFO.phone}`),
};
