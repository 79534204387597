import { Auth0Provider } from '@auth0/auth0-react';

import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from '@/constants/env';

interface AuthenticationProviderProps {
  children: React.ReactNode;
}

export function AuthenticationProvider(
  props: AuthenticationProviderProps,
): JSX.Element {
  const { children } = props;

  return (
    <Auth0Provider
      audience={AUTH0_AUDIENCE}
      clientId={AUTH0_CLIENT_ID}
      domain={AUTH0_DOMAIN}
      redirectUri={window.location.origin}
      useRefreshTokens
    >
      {children}
    </Auth0Provider>
  );
}
