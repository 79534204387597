import * as z from 'zod';

import { FIXED_COUNTRY } from '@/constants/misc';
import { COUNTRY_LIST } from '@/constants/us-countries';
import { US_STATES } from '@/constants/us-states';

export const baseMailingAddressFormSchema = z.object({
  'mailing-city': z.string().min(1, 'City is required'),
  'mailing-country': z
    .enum(COUNTRY_LIST, {
      errorMap: () => {
        return { message: 'Please select a Country' };
      },
    })
    .default(FIXED_COUNTRY),
  'mailing-postal-code': z.string().min(1, 'Postal Code is required'),
  'mailing-state': z
    .enum(US_STATES, {
      errorMap: () => {
        return { message: 'Please select a State' };
      },
    })
    .nullable(),
  'mailing-street-address': z.string().min(1, 'Street Address is required'),
  'mailing-unit-number': z.string().nullable(),
});

export type MailingAddressFormSchema = z.infer<
  typeof baseMailingAddressFormSchema
>;
