import { useQuery } from '@tanstack/react-query';

import { EndpointKeys } from '@/api/keys';

import type { BusinessDetails } from '@/types/business';

import getBusiness from './getBusiness';

import type {
  UseGetBusinessOutput,
  UseGetBusinessQueryOptions,
  UseGetBusinessVariables,
} from './types';

const useGetBusiness = (
  variables: UseGetBusinessVariables<true>,
  queryOptions?: UseGetBusinessQueryOptions,
): UseGetBusinessOutput => {
  const { businessId } = variables;

  const queryWillBeEnabled = typeof businessId === 'string';

  return useQuery<BusinessDetails, Error>(
    [EndpointKeys.UseGetBusiness],
    // eslint-disable-next-line @typescript-eslint/promise-function-async
    () => getBusiness({ businessId: businessId ?? '' }),
    { enabled: queryWillBeEnabled, ...queryOptions },
  );
};

export default useGetBusiness;
