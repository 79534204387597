import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { CSS_VARS } from '@/constants/css-vars';
import { ICONS } from '@/constants/icons';
import Box from '@mui/material/Box';
import { StyledLinearProgress } from './style';

interface ButtonProps {
  disabled?: boolean;
  show?: boolean;
  text?: string;
  onClick?: () => void;
}

interface ControlBarProps {
  backButtonProps?: ButtonProps;
  nextButtonProps?: ButtonProps;
  isLoading?: boolean;
  loadingText?: string;
  showInfoText?: boolean;
}

const ControlBar = (props: ControlBarProps): JSX.Element => {
  const {
    backButtonProps,
    nextButtonProps,
    isLoading,
    loadingText = 'Saving your information',
    showInfoText = false,
  } = props;

  const {
    disabled: disabledBackButton,
    onClick: onClickBackButton,
    show: showBackButton = true,
    text: backButtonText = 'Back',
  } = backButtonProps ?? {};

  const {
    disabled: disabledNextButton,
    onClick: onClickNextButton,
    show: showNextButton = true,
    text: nextButtonText = 'Next',
  } = nextButtonProps ?? {};

  const infoText = `You can save and continue anywhere throughout the application process. You will be returned to the beginning of the section you were working in when logged out of the application.`;

  return (
    <Grid container>
      <Grid
        container
        flexDirection="row"
        justifyContent="space-around"
        width="100%"
        marginTop="1rem"
      >
        {isLoading && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '16px 36px',
              gap: '4px',
              width: '27rem',

              backgroundColor: '#E7F4F7',
              border: '1px solid #37BEDD',
              boxShadow: '2px 2px 4px rgba(37, 77, 115, 0.2)',
              borderRadius: '8px',
            }}
          >
            <Typography>{loadingText}</Typography>
            <StyledLinearProgress />
          </Box>
        )}
      </Grid>

      {showInfoText && (
        <Grid
          marginTop={isLoading ? '1rem' : '6rem'}
          marginBottom="1rem"
          justifyContent="center"
          alignItems="center"
        >
          <Typography fontSize="1rem" fontWeight="500" lineHeight="1.6rem">
            {infoText}
          </Typography>
        </Grid>
      )}

      <Grid
        borderTop="0.2rem solid #f2f2f2"
        component="footer"
        container
        justifyContent="space-between"
        marginTop={showInfoText ? 0 : '1rem'}
        padding="1.5rem"
      >
        {showBackButton && (
          <Button
            disabled={disabledBackButton}
            startIcon={
              <ICONS.ArrowRoundBack
                color={`var(${CSS_VARS.Colors.Gray[400]})`}
                size={24}
              />
            }
            onClick={onClickBackButton}
          >
            <Typography
              color={`var(${CSS_VARS.Colors.Gray[400]})`}
              fontWeight={700}
              variant="h5"
            >
              {backButtonText}
            </Typography>
          </Button>
        )}

        {/* if there is not a <div />, the next button is right-aligned per flex */}
        {!showBackButton && <div />}

        {showNextButton && (
          <Button
            disabled={disabledNextButton}
            variant="contained"
            onClick={onClickNextButton}
            sx={{
              backgroundColor: '#254D73',
            }}
          >
            <Typography
              color={`var(${CSS_VARS.Colors.White})`}
              fontWeight={700}
              variant="h5"
            >
              {nextButtonText}
            </Typography>
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default ControlBar;
