import useAuthenticatedUser from '@/api/hooks/useAuthenticateUser';
import useGetApplicationFeedbacks from '@/api/hooks/useGetApplicationFeedbacks';
import useGetBusiness from '@/api/hooks/useGetBusiness';
import useGetBusinessApplicationProgress from '@/api/hooks/useGetBusinessApplicationProgress';
import useGetBusinessFacilityInformation from '@/api/hooks/useGetBusinessFacilityInformation';
import useGetBusinessValidation from '@/api/hooks/useGetBusinessValidation';
import { ApplicationStatusEnum } from '@/api/hooks/useGetBusinessValidation/type';
import useGetFacilities from '@/api/hooks/useGetFacilities';
import useUpdateBusinessApplicationProgress from '@/api/hooks/useUpdateBusinessApplicationProgress';
import useUpdateFacilityInformation from '@/api/hooks/useUpdateFacilityInformation';
import { Router } from '@/constants/router';
import ControlBar from '@components/UI/ControlBar';
import { zodResolver } from '@hookform/resolvers/zod';
import Grid from '@mui/material/Grid';
import { BANKING_ACTIVITY_SECTION_ID } from '@pages/Facilities/Sections/BankingActivity/data';
import { useFacilitySectionData } from '@pages/Facilities/hooks/useFacilitySectionData';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import InputField from './components/InputField';
import {
  PRIMARY_BUSINESS_PAGE_ID,
  formSchema,
  getNormalizedData,
  setPrimaryBusinessValues,
} from './data';
import type { FormSchema } from './types';

function PrimaryBusiness(): JSX.Element {
  const { data: authenticatedUser } = useAuthenticatedUser();
  const { businesses } = authenticatedUser ?? {};
  const { id: businessId } = businesses?.[0] ?? {};
  const navigate = useNavigate();
  const updateFacilityInformation = useUpdateFacilityInformation();
  const { data: business } = useGetBusiness({ businessId });

  const { facilityId } = useFacilitySectionData({
    title: 'Tell us more about the primary licensed businesses you work with.',
    description: 'Please provide the following for your top 3 vendors. ',
    pageNumber: 18,
  });

  useGetFacilities(businessId ?? '', false, {
    enabled: businessId != null,
  });

  const finishedOnboard = !!business?.finishedOnboard;

  const updateBusinessApplicationProgress =
    useUpdateBusinessApplicationProgress();

  const {
    data: businessApplicationProgress,
    refetch: refetchBusinessProgress,
  } = useGetBusinessApplicationProgress(businessId ?? '', {
    enabled: businessId != null,
  });

  const {
    getValues,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
  });
  const {
    data: businessFacilityInformation,
    isLoading: isBusinessFacilityLoading,
  } = useGetBusinessFacilityInformation(
    businessId ?? '',
    facilityId ?? '',
    BANKING_ACTIVITY_SECTION_ID,
    PRIMARY_BUSINESS_PAGE_ID,
    { enabled: businessId != null && facilityId != null },
  );
  useEffect(() => {
    if (isBusinessFacilityLoading || businessFacilityInformation == null)
      return;
    setPrimaryBusinessValues(businessFacilityInformation, setValue);
  }, [isBusinessFacilityLoading, businessFacilityInformation, setValue]);

  const isLoading = updateFacilityInformation.isLoading;

  const { data: businessValidation } = useGetBusinessValidation(
    businessId ?? '',
    {
      enabled: businessId != null,
    },
  );

  const { data: applicationFeedbacks } = useGetApplicationFeedbacks(
    facilityId ?? '',
    {
      enabled:
        facilityId != null &&
        businessValidation?.status ===
          ApplicationStatusEnum.REVIEW_INFO_REQUESTED,
    },
  );

  const hasFinishedOnboard = business?.finishedOnboard;

  const isApplicationResubmission =
    businessValidation?.status === ApplicationStatusEnum.REVIEW_INFO_REQUESTED;

  const onSubmitHandler: SubmitHandler<FormSchema> = (data): void => {
    if (businessId == null || facilityId == null) return;
    const sectionData = getNormalizedData(data);
    updateFacilityInformation.mutate(
      {
        businessId,
        facilityId,
        section: sectionData,
      },
      {
        async onSuccess() {
          if (!finishedOnboard) {
            await updateBusinessApplicationProgress.mutateAsync(
              {
                businessId,
                pagesCompleted:
                  businessFacilityInformation?.id !== ''
                    ? undefined
                    : (businessApplicationProgress?.pagesCompleted ?? 0) + 1,
              },
              {
                onSuccess() {
                  refetchBusinessProgress().catch(console.error);
                  navigate(
                    Router.Onboarding.Facilities.BusinessActivity.ATMLocation,
                  );
                },
              },
            );
          } else {
            navigate(
              Router.BankAccount.Facilities.BusinessActivity.ThirdPartyPayment,
            );
          }
        },
      },
    );
  };

  return (
    <>
      <Grid sx={{ margin: '30px 5px' }}>
        <InputField
          register={register}
          errors={errors}
          business="business-name-1"
          volume="transaction-volume-1"
          getValues={getValues}
          required={true}
          disabled={
            businessValidation?.status ===
            ApplicationStatusEnum.REVIEW_INFO_REQUESTED
              ? !(applicationFeedbacks ?? []).filter(
                  (feedback) => feedback.section === 'business-name-1',
                ).length
              : false
          }
          errorFlag={
            businessValidation?.status ===
            ApplicationStatusEnum.REVIEW_INFO_REQUESTED
              ? !!(applicationFeedbacks ?? []).filter(
                  (feedback) => feedback.section === 'business-name-1',
                ).length
              : false
          }
          disabledVolume={
            businessValidation?.status ===
            ApplicationStatusEnum.REVIEW_INFO_REQUESTED
              ? !(applicationFeedbacks ?? []).filter(
                  (feedback) => feedback.section === 'business-volume-1',
                ).length
              : false
          }
          errorFlagVolume={
            businessValidation?.status ===
            ApplicationStatusEnum.REVIEW_INFO_REQUESTED
              ? !!(applicationFeedbacks ?? []).filter(
                  (feedback) => feedback.section === 'business-volume-1',
                ).length
              : false
          }
        />
        <InputField
          register={register}
          errors={errors}
          business="business-name-2"
          volume="transaction-volume-2"
          getValues={getValues}
          disabled={
            businessValidation?.status ===
            ApplicationStatusEnum.REVIEW_INFO_REQUESTED
              ? !(applicationFeedbacks ?? []).filter(
                  (feedback) => feedback.section === 'business-name-2',
                ).length
              : false
          }
          errorFlag={
            businessValidation?.status ===
            ApplicationStatusEnum.REVIEW_INFO_REQUESTED
              ? !!(applicationFeedbacks ?? []).filter(
                  (feedback) => feedback.section === 'business-name-2',
                ).length
              : false
          }
          disabledVolume={
            businessValidation?.status ===
            ApplicationStatusEnum.REVIEW_INFO_REQUESTED
              ? !(applicationFeedbacks ?? []).filter(
                  (feedback) => feedback.section === 'business-volume-2',
                ).length
              : false
          }
          errorFlagVolume={
            businessValidation?.status ===
            ApplicationStatusEnum.REVIEW_INFO_REQUESTED
              ? !!(applicationFeedbacks ?? []).filter(
                  (feedback) => feedback.section === 'business-volume-2',
                ).length
              : false
          }
        />
        <InputField
          register={register}
          errors={errors}
          business="business-name-3"
          volume="transaction-volume-3"
          getValues={getValues}
          disabled={
            businessValidation?.status ===
            ApplicationStatusEnum.REVIEW_INFO_REQUESTED
              ? !(applicationFeedbacks ?? []).filter(
                  (feedback) => feedback.section === 'business-name-3',
                ).length
              : false
          }
          errorFlag={
            businessValidation?.status ===
            ApplicationStatusEnum.REVIEW_INFO_REQUESTED
              ? !!(applicationFeedbacks ?? []).filter(
                  (feedback) => feedback.section === 'business-name-3',
                ).length
              : false
          }
          disabledVolume={
            businessValidation?.status ===
            ApplicationStatusEnum.REVIEW_INFO_REQUESTED
              ? !(applicationFeedbacks ?? []).filter(
                  (feedback) => feedback.section === 'business-volume-3',
                ).length
              : false
          }
          errorFlagVolume={
            businessValidation?.status ===
            ApplicationStatusEnum.REVIEW_INFO_REQUESTED
              ? !!(applicationFeedbacks ?? []).filter(
                  (feedback) => feedback.section === 'business-volume-3',
                ).length
              : false
          }
        />
      </Grid>
      <ControlBar
        showInfoText={!hasFinishedOnboard && !isApplicationResubmission}
        backButtonProps={{
          onClick: () =>
            navigate(
              Router.Onboarding.Facilities.BankingActivity
                .BankingActivityDetail,
            ),
        }}
        nextButtonProps={{
          onClick: handleSubmit(onSubmitHandler),
          disabled:
            updateBusinessApplicationProgress.isLoading ||
            updateFacilityInformation.isLoading,
        }}
        isLoading={isLoading}
      />
    </>
  );
}

export default PrimaryBusiness;
