import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { ICONS } from '@/constants/icons';
import {
  BusinessDetailsRoutes,
  BusinessDocumentsRoutes,
  BusinessOwnershipRoutes,
  BusinessOperatorDetailsRoutes,
  FacilitiesRoutes,
  ReviewAndSignRoutes,
} from '@/constants/router';

import type { ValueOf } from '@/types/utils';

export enum IconStatus {
  Completed = 'completed',
  Pending = 'pending',
  Selected = 'selected',
  Rejected = 'rejected',
}

export const navigationStep = {
  BusinessDetails: BusinessDetailsRoutes.__BasePath__,
  BusinessDocuments: BusinessDocumentsRoutes.__BasePath__,
  BusinessOwnership: BusinessOwnershipRoutes.__BasePath__,
  Facilities: FacilitiesRoutes.__BasePath__,
  BusinessOperatorDetails: BusinessOperatorDetailsRoutes.__BasePath__,
  ReviewAndSign: ReviewAndSignRoutes.__BasePath__,
};

const iconSize = {
  height: 16,
  width: 16,
};

export const navigationIcon: Record<IconStatus, React.ReactNode> = {
  completed: <ICONS.CircleCheckIcon {...iconSize} />,
  pending: <ICONS.InProgressIcon {...iconSize} />,
  selected: <ICONS.OutlineCircleIcon {...iconSize} />,
  rejected: <ICONS.ErrorIcon {...iconSize} />,
};

export interface NavigationItem {
  id: ValueOf<typeof navigationStep>;
  path: string;
  text: string;
}

interface NavigationState {
  currentStepId: ValueOf<typeof navigationStep>;
  setCurrentStepId: (id: ValueOf<typeof navigationStep>) => void;
  stepsCompleted: string[];
  addStepCompleted: (step: string) => void;
}

export const useNavigationState = create<NavigationState>()(
  devtools(
    persist(
      (set) => ({
        currentStepId: navigationStep.BusinessDetails,
        stepsCompleted: [],
        setCurrentStepId: (id) =>
          set(
            (state) => {
              return {
                ...state,
                currentStepId: id,
              };
            },
            false,
            'navigation/setCurrentStepId',
          ),
        addStepCompleted: (step) =>
          set((state) => {
            return { stepsCompleted: [step, ...state.stepsCompleted] };
          }),
      }),
      {
        name: 'navigation-storage',
      },
    ),
  ),
);
