import List from '@mui/material/List';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import type { LocationNavItem } from '../../data';
import LocationNavButton from '../LocationNavButton';

interface LocationNavIteratorProps {
  list: LocationNavItem[];
}

const LocationNavIterator = (props: LocationNavIteratorProps): JSX.Element => {
  const { list } = props;

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const location = useLocation();

  useEffect(() => {
    const index = list.findIndex((item) => {
      const itemPath = item.path.split('/');
      const locationPath = location.pathname.split('/');

      return itemPath[2] === locationPath[2];
    });

    if (index !== selectedIndex) {
      setSelectedIndex(index);
    }
  }, [location, list, selectedIndex]);

  return (
    <nav>
      <List disablePadding>
        {list.map((listItem, index) => (
          <LocationNavButton
            {...listItem}
            key={index}
            isSelected={selectedIndex === index}
          />
        ))}
      </List>
    </nav>
  );
};

export default LocationNavIterator;
