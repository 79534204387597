import '@/index.css';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from '@components/App';

import { AuthenticationProvider } from '@lib/auth0/Provider';
import { DataFetchingProvider } from '@lib/react-query/Provider';

import { theme } from './theme';
import { analyticsLoad } from '@/utils/analytics';

const rootElement = document.querySelector<HTMLDivElement>('#root');

if (rootElement == null) throw new Error('`root` element was not found');

analyticsLoad();

createRoot(rootElement).render(
  <StrictMode>
    <DataFetchingProvider>
      <AuthenticationProvider>
        <BrowserRouter>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider<typeof theme> theme={theme}>
              <CssBaseline />
              <App />
            </ThemeProvider>
          </LocalizationProvider>
        </BrowserRouter>
      </AuthenticationProvider>
    </DataFetchingProvider>
  </StrictMode>,
);
