import Grid from '@mui/material/Grid';

import { CSS_VARS } from '@/constants/css-vars';
import { ICONS } from '@/constants/icons';

import { ParentPageItem } from '../index';

interface ParentPageItemWithIconProps {
  isActive: boolean;
  text: string;
}

const ParentPageItemWithIcon = (
  props: ParentPageItemWithIconProps,
): JSX.Element => {
  const { isActive, text } = props;

  return (
    <Grid alignItems="center" container gap={1.5} width="fit-content">
      <ICONS.OutlineArrowNarrowRight
        color={`var(${CSS_VARS.Colors.Gray[400]})`}
        size="1.8rem"
      />

      <ParentPageItem isActive={isActive} text={text} />
    </Grid>
  );
};

export default ParentPageItemWithIcon;
