import Box from '@mui/material/Box';
import { StyledLinearProgress } from './style';
import useGetBusinessApplicationProgress from '@/api/hooks/useGetBusinessApplicationProgress';
import { useBusinessId } from '@/utils/hooks/useBusinessId';
import { useAuth0 } from '@auth0/auth0-react';

const ProgressBarComponent = (): JSX.Element => {
  const { isAuthenticated, isLoading } = useAuth0();

  const isUserAuthed = isAuthenticated && !isLoading;
  const businessId = useBusinessId(isUserAuthed);

  const { data: businessApplicationProgress } =
    useGetBusinessApplicationProgress(businessId ?? '', {
      enabled: businessId != null,
    });

  const pageCalculation =
    ((businessApplicationProgress?.pagesCompleted ?? 0) / 24) * 100;

  const pages = pageCalculation > 100 ? 100 : pageCalculation;

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '1.4rem',
        margin: '0 auto 3rem',
        padding: '0.2rem',
        backgroundColor: '#E7F4F7',
        border: '1px solid #37BEDD',
        borderRadius: '2px',
      }}
    >
      <StyledLinearProgress variant="determinate" value={pages} />
    </Box>
  );
};

export default ProgressBarComponent;
