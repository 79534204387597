import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import type { FieldErrors } from 'react-hook-form';

import InputDropdown from '@/components/Forms/InputDropdown';
import InputText from '@/components/Forms/InputText';

import { FIXED_COUNTRY } from '@/constants/misc';
import { COUNTRY_LIST } from '@/constants/us-countries';
import { StatesEmpty, US_STATES } from '@/constants/us-states';

import type { CustomRegister } from '../types';

import type { MailingAddressFormSchema } from './type';
import type { SelectChangeEvent } from '@mui/material/Select';
import type { GetBusinessInformationFeedbacks } from '@/api/hooks/useGetBusinessInformationFeedbacks/types';

const normalizedCountryList = COUNTRY_LIST.map((country) => ({
  id: country,
  value: country,
  label: country,
}));

const normalizedUsStates = US_STATES.map((state) => {
  return {
    id: state,
    value: state,
    label: state,
  };
});

interface MailingAddressFormProps {
  register: CustomRegister<MailingAddressFormSchema>;
  errors: FieldErrors<MailingAddressFormSchema>;
  stateMailingAddress: StatesEmpty;
  handleMailingStateAddressChange: (data: SelectChangeEvent<unknown>) => void;
  disabled?: boolean;
  feedbackFields?: GetBusinessInformationFeedbacks[];
  isUnderReview?: boolean;
}

function MailingAddressForm(props: MailingAddressFormProps): JSX.Element {
  const {
    register,
    errors,
    stateMailingAddress,
    handleMailingStateAddressChange,
    disabled = false,
    feedbackFields = [],
    isUnderReview = false,
  } = props;

  return (
    <Box
      component="section"
      sx={{
        marginBottom: '2rem',
      }}
    >
      <Grid
        container
        direction="row"
        spacing={2}
        sx={{ marginBottom: '2rem', display: 'flex' }}
      >
        <Grid item>
          <InputText
            {...register('mailing-street-address')}
            label="Street Address"
            required
            name="mailing-street-address"
            width={'43rem'}
            fieldErrors={errors['mailing-street-address']}
            helperText={errors['mailing-street-address']?.message}
            showHelperText
            disabled={
              disabled || feedbackFields.length || isUnderReview
                ? !(feedbackFields ?? []).filter((feedback) =>
                    ['mailing-street-address'].includes(feedback.section),
                  ).length
                : false
            }
            errorFlag={
              feedbackFields.length
                ? !!(feedbackFields ?? []).filter((feedback) =>
                    ['mailing-street-address'].includes(feedback.section),
                  ).length
                : false
            }
          />
        </Grid>
        <Grid item>
          <InputText
            {...register('mailing-unit-number')}
            label="Apartment / Suite #"
            name="mailing-unit-number"
            placeholder="Unit/Suite #"
            width={'14rem'}
            fieldErrors={errors['mailing-unit-number']}
            helperText={errors['mailing-unit-number']?.message}
            showHelperText
            disabled={
              disabled || feedbackFields.length || isUnderReview
                ? !(feedbackFields ?? []).filter((feedback) =>
                    ['mailing-unit-number'].includes(feedback.section),
                  ).length
                : false
            }
            errorFlag={
              feedbackFields.length
                ? !!(feedbackFields ?? []).filter((feedback) =>
                    ['mailing-unit-number'].includes(feedback.section),
                  ).length
                : false
            }
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        spacing={2}
        sx={{ marginBottom: '2rem', display: 'flex' }}
      >
        <Grid item>
          <InputText
            {...register('mailing-city')}
            label="City"
            name="mailing-city"
            required
            width={'43rem'}
            fieldErrors={errors['mailing-city']}
            helperText={errors['mailing-city']?.message}
            showHelperText
            disabled={
              disabled || feedbackFields.length || isUnderReview
                ? !(feedbackFields ?? []).filter((feedback) =>
                    ['mailing-city'].includes(feedback.section),
                  ).length
                : false
            }
            errorFlag={
              feedbackFields.length
                ? !!(feedbackFields ?? []).filter((feedback) =>
                    ['mailing-city'].includes(feedback.section),
                  ).length
                : false
            }
          />
        </Grid>
        <Grid item>
          <InputDropdown<StatesEmpty>
            {...register('mailing-state')}
            label={'State'}
            fieldErrors={errors['mailing-state']}
            helperText={errors['mailing-state']?.message}
            options={normalizedUsStates}
            placeholder="Select State"
            showHelperText
            width={'14rem'}
            value={stateMailingAddress}
            required
            onChange={handleMailingStateAddressChange}
            disabled={
              disabled || feedbackFields.length || isUnderReview
                ? !(feedbackFields ?? []).filter((feedback) =>
                    ['mailing-state'].includes(feedback.section),
                  ).length
                : false
            }
            errorFlag={
              feedbackFields.length
                ? !!(feedbackFields ?? []).filter((feedback) =>
                    ['mailing-state'].includes(feedback.section),
                  ).length
                : false
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} direction="row" sx={{ display: 'flex' }}>
        <Grid item>
          <InputText
            {...register('mailing-postal-code')}
            type={'number'}
            label="Postal Code"
            required
            name="mailing-postal-code"
            width={'17rem'}
            fieldErrors={errors['mailing-postal-code']}
            helperText={errors['mailing-postal-code']?.message}
            showHelperText
            disabled={
              disabled || feedbackFields.length || isUnderReview
                ? !(feedbackFields ?? []).filter((feedback) =>
                    ['mailing-postal-code'].includes(feedback.section),
                  ).length
                : false
            }
            errorFlag={
              feedbackFields.length
                ? !!(feedbackFields ?? []).filter((feedback) =>
                    ['mailing-postal-code'].includes(feedback.section),
                  ).length
                : false
            }
          />
        </Grid>
        <Grid item>
          <InputDropdown<typeof COUNTRY_LIST[number]>
            {...register('mailing-country')}
            defaultValue={FIXED_COUNTRY}
            fieldErrors={errors['mailing-country']}
            helperText={errors['mailing-country']?.message}
            label={'Country'}
            required
            name={'mailing-country'}
            options={normalizedCountryList}
            placeholder="Select Country"
            showHelperText
            width={'17rem'}
            disabled={
              disabled || feedbackFields.length || isUnderReview
                ? !(feedbackFields ?? []).filter((feedback) =>
                    ['mailing-country'].includes(feedback.section),
                  ).length
                : false
            }
            errorFlag={
              feedbackFields.length
                ? !!(feedbackFields ?? []).filter((feedback) =>
                    ['mailing-country'].includes(feedback.section),
                  ).length
                : false
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default MailingAddressForm;
