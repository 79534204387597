import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { StorageKeys } from '@/constants/misc';
import { Router } from '@/constants/router';

import useAuthenticatedUser from '@/api/hooks/useAuthenticateUser';
import LoadingComponent from '@/components/UI/LoadingComponent';
import { Grid } from '@mui/material';
import useGetBusinessValidation from '@/api/hooks/useGetBusinessValidation';
import { useBusinessId } from '@/utils/hooks/useBusinessId';
import { ApplicationStatusEnum } from '@/api/hooks/useGetBusinessValidation/type';

const PATHS_TO_NAVIGATE = {
  businessDetails: Router.Onboarding.BusinessDetails.About,
  // TODO: update this path
  eligibility: '/eligibility',
  // TODO: update this path
  notEligible: '/eligibility/status?sucess=false',
  successPage: '/review-and-sign/success',
};

function HomePage(): JSX.Element {
  const navigate = useNavigate();

  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
  } = useAuth0();

  const [startUserAuthentication, setStartUserAuthentication] = useState(false);

  const [redirectInProgress, setRedirectInProgress] = useState(false);

  const { data: authenticatedUserData, isLoading: isAuthenticatingUser } =
    useAuthenticatedUser({
      enabled: startUserAuthentication,
    });

  const isUserAuthed = !isLoading && isAuthenticated;

  const businessId = useBusinessId(isUserAuthed);

  const { data: businessValidation } = useGetBusinessValidation(
    businessId ?? '',
    {
      enabled: businessId != null,
    },
  );

  const isUserEligible = authenticatedUserData?.isEligible;

  useEffect(() => {
    if (!isUserAuthed) return;

    const getUserToken = async (): Promise<void> => {
      const userToken = await getAccessTokenSilently();

      localStorage.setItem(StorageKeys.Token, userToken);

      setStartUserAuthentication(true);
    };

    getUserToken().catch(console.error);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserAuthed]);

  useEffect(() => {
    if (!startUserAuthentication || isAuthenticatingUser) return;

    if (isUserEligible == null) {
      navigate(PATHS_TO_NAVIGATE.eligibility, { replace: true });

      return;
    }

    let pathToNavigate;

    if (!isUserEligible) {
      pathToNavigate = PATHS_TO_NAVIGATE.notEligible;
      return navigate(pathToNavigate, { replace: true });
    }

    if (!businessValidation) return;

    if (
      isUserEligible &&
      businessValidation.status === ApplicationStatusEnum.REVIEW_INFO_REQUESTED
    ) {
      pathToNavigate = PATHS_TO_NAVIGATE.successPage;
    } else {
      pathToNavigate = PATHS_TO_NAVIGATE.businessDetails;
    }

    navigate(pathToNavigate, { replace: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAuthenticatingUser,
    isUserEligible,
    startUserAuthentication,
    businessValidation,
  ]);

  if (!isAuthenticated && !redirectInProgress && !isLoading) {
    setRedirectInProgress(true);
    loginWithRedirect().catch(console.error);
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: '100vh' }}
    >
      <Grid item>
        <LoadingComponent />
      </Grid>
    </Grid>
  );
}

export default HomePage;
