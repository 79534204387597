import { ReactComponent as CircleCheckIcon } from '@/assets/icons/check_icon.svg';
import { ReactComponent as CheveronRightIcon } from '@/assets/icons/cheveron_right_icon.svg';
import { ReactComponent as OutlineCircleIcon } from '@/assets/icons/circle_icon.svg';
import { ReactComponent as ErrorIcon } from '@/assets/icons/error_icon.svg';
import { ReactComponent as InformationCircleIcon } from '@/assets/icons/information_circle_icon.svg';
import { ReactComponent as InProgressIcon } from '@/assets/icons/in_progress_icon.svg';
import { ReactComponent as LoginCards } from '@/assets/icons/login_cards.svg';
import { ReactComponent as Logout } from '@/assets/icons/logout_icon.svg';
import { ReactComponent as MakeWire } from '@/assets/icons/make_wire.svg';
import { ReactComponent as PayboticLogoSimpleIcon } from '@/assets/icons/paybotic_logo_simple.svg';
import { ReactComponent as RectangleIcon } from '@/assets/icons/rectangle_icon.svg';
import { ReactComponent as VerifyEmail } from '@/assets/icons/verify_email.svg';
import { ReactComponent as WriteCheckIcon } from '@/assets/icons/write_check_icon.svg';
import { ReactComponent as AlertIcon } from '@/assets/icons/alert_icon.svg';

import { AiFillEye } from 'react-icons/ai';

import {
  BiCheckCircle,
  BiCheckShield as CheckShield,
  BiInfoCircle,
  BiRadioCircle,
} from 'react-icons/bi';

import { CgInfo as Info } from 'react-icons/cg';

import {
  FiArrowRightCircle,
  FiDownload as Download,
  FiEdit as EditIcon,
  FiLock as LockIcon,
  FiRefreshCw as RefreshIcon,
  FiXOctagon as OctagonIcon,
} from 'react-icons/fi';

import {
  HiOutlineArrowNarrowRight as OutlineArrowNarrowRight,
  HiOutlineOfficeBuilding as OutlineOfficeBuilding,
  HiOutlineCalendar as OutlineCalendar,
  HiOutlineCash as OutlineCash,
  HiOutlineCheckCircle as OutlineCheckCircle,
  HiOutlineLibrary as OutlineLibrary,
  HiOutlineUser as OutlineUser,
  HiOutlinePencil as Pencil,
  HiOutlineShieldCheck as ShieldCheck,
  HiOutlineTrash as Trash,
  HiOutlineDownload as DownloadOutlined,
} from 'react-icons/hi';

import { ImCheckboxChecked } from 'react-icons/im';

import {
  IoMdArrowRoundBack as ArrowRoundBack,
  IoMdClose as Close,
  IoMdEyeOff,
} from 'react-icons/io';

import {
  IoCheckmark as CheckmarkIcon,
  IoChevronBack as CheveronLeftIcon,
} from 'react-icons/io5';

import {
  MdArrowForward as ArrowForward,
  MdExpandMore,
  MdMailOutline as MailOutline,
  MdPhone as Phone,
  MdKeyboardArrowRight,
} from 'react-icons/md';

import { RiBankLine as MakeACH, RiPercentLine } from 'react-icons/ri';

import { TbLink } from 'react-icons/tb';

export const ICONS = {
  AiFillEye,
  ArrowForward,
  ArrowRoundBack,
  BiCheckCircle,
  BiInfoCircle,
  BiRadioCircle,
  CheckmarkIcon,
  CheckShield,
  CheveronLeftIcon,
  CheveronRightIcon,
  CircleCheckIcon,
  Close,
  Download,
  EditIcon,
  ErrorIcon,
  FiArrowRightCircle,
  ImCheckboxChecked,
  Info,
  InformationCircleIcon,
  InProgressIcon,
  IoMdEyeOff,
  LockIcon,
  LoginCards,
  Logout,
  MailOutline,
  MakeACH,
  MakeWire,
  MdExpandMore,
  MdKeyboardArrowRight,
  OctagonIcon,
  OutlineArrowNarrowRight,
  OutlineCalendar,
  OutlineCash,
  OutlineCheckCircle,
  OutlineCircleIcon,
  OutlineLibrary,
  OutlineOfficeBuilding,
  OutlineUser,
  PayboticLogoSimpleIcon,
  Pencil,
  Phone,
  RectangleIcon,
  RefreshIcon,
  RiPercentLine,
  ShieldCheck,
  TbLink,
  Trash,
  WriteCheckIcon,
  VerifyEmail,
  AlertIcon,
  DownloadOutlined,
} as const;
