import type { BusinessDocument, BusinessDocumentType } from '@/types/business';

import fetcher from '@/utils/fetcher';

// eslint-disable-next-line @typescript-eslint/promise-function-async
const getBusinessDocuments = <
  DocumentType extends BusinessDocumentType = BusinessDocumentType,
>(
  businessId: string,
  facilityId?: string,
): Promise<Array<BusinessDocument<DocumentType>>> => {
  let endpoint = `/business/${businessId}/documents`;

  if (facilityId != null) {
    endpoint += `?facilityId=${facilityId}`;
  }

  return fetcher<Array<BusinessDocument<DocumentType>>>({
    endpoint,
  });
};

export default getBusinessDocuments;
