import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { SectionDto } from '@/api/hooks/useUpdateFacilityInformation/types';

import getBusinessFacilityInformation from './getBusinessFacilityInformation';

const useGetBusinessFacilityInformation = (
  businessId: string,
  facilityId: string,
  sectionId: string,
  pageId: string,
  queryOptions?: UseQueryOptions<SectionDto, Error>,
): UseQueryResult<SectionDto, Error> =>
  useQuery<SectionDto, Error>(
    [`useGetBusinessFacilityInformation/${facilityId}/${pageId}/${sectionId}`],
    // eslint-disable-next-line @typescript-eslint/promise-function-async
    () =>
      getBusinessFacilityInformation(businessId, facilityId, sectionId, pageId),
    queryOptions,
  );

export default useGetBusinessFacilityInformation;
