import { nanoid } from 'nanoid';

import { Router } from '@/constants/router';

import type { NavigationItem } from '@/features/ui/navigation';
import { SystemUsersValue } from '@pages/BankAccount/SystemUsers/__utils__/mock';

const bankAccountNavItems: NavigationItem[] = [
  {
    id: nanoid(),
    path: Router.BankAccount.Dashboard,
    text: 'Dashboard',
  },
  {
    id: nanoid(),
    path: Router.BankAccount.Overview,
    text: 'Account Overview',
  },
  {
    id: nanoid(),
    path: Router.BankAccount.PaymentsAndPayees,
    text: 'Payments & Payees',
  },
  {
    id: nanoid(),
    path: Router.BankAccount.AuthorizedUsers,
    text: 'Authorized Cardholders',
  },
  {
    id: nanoid(),
    path: Router.BankAccount.BankAccountDetails,
    text: 'Business Details',
  },
  {
    id: nanoid(),
    path: Router.BankAccount.LocationDetails,
    text: 'Location Details',
  },
  {
    id: nanoid(),
    path: Router.BankAccount.SystemUsers,
    text: 'System Users',
  },
  {
    id: nanoid(),
    path: Router.BankAccount.Reports,
    text: 'Reports',
  },
];

export const navItems = (
  userRole: string,
): { bankAccount: NavigationItem[] } => {
  if (userRole === SystemUsersValue.BUSINESS_OPERATOR)
    return {
      bankAccount: bankAccountNavItems,
    };
  return {
    bankAccount: bankAccountNavItems.filter(
      (navItem) =>
        navItem.path === Router.BankAccount.SystemUsers ||
        navItem.path === Router.BankAccount.LocationDetails ||
        navItem.path === Router.BankAccount.Reports,
    ),
  };
};
