import { BankAccountRoutes } from '@/constants/router';

import type { SystemUserAuthResponse } from '@/types/user';
import { nanoid } from 'nanoid';
import { lazy } from 'react';

const LazyAccountOverview = lazy(
  async () => await import('@pages/BankAccount/AccountOverview'),
);

const LazyAccountDashboard = lazy(
  async () => await import('@pages/BankAccount/Dashboard'),
);

const LazyAuthorizedUsers = lazy(
  async () => await import('@pages/BankAccount/AuthorizedUsers'),
);

const LazySystemUsers = lazy(
  async () => await import('@pages/BankAccount/SystemUsers'),
);

const LazyPaymentsAndPayees = lazy(
  async () => await import('@pages/BankAccount/PaymentsAndPayees'),
);

const LazyPayment = lazy(
  async () => await import('@pages/BankAccount/Payments/Payment'),
);

const LazyReview = lazy(
  async () => await import('@pages/BankAccount/Payments/Review'),
);

const LazyDepositCheck = lazy(
  async () => await import('@pages/BankAccount/Payments/DepositCheck/index'),
);

const LazyCompleteDepositCheck = lazy(
  async () =>
    await import(
      '@pages/BankAccount/Payments/DepositCheck/CompleteDepositCheck'
    ),
);
const LazyPaymentSuccess = lazy(
  async () => await import('@pages/BankAccount/Payments/components/Success'),
);

const LazyBankAccountDetails = lazy(
  async () =>
    await import('@/pages/BankAccount/BankAccountDetails/BusinessDetails'),
);

const LazyLocationDetails = lazy(
  async () =>
    await import('@/pages/BankAccount/BankAccountDetails/LocationDetails'),
);

const LazyReports = lazy(
  async () => await import('@/pages/BankAccount/Reports'),
);

interface IBankAccountComponent {
  id: string;
  path: string;
  component: (prop?: SystemUserAuthResponse) => JSX.Element;
}

interface IBankAccountComponents {
  SystemUsers: IBankAccountComponent;
  Dashboard: IBankAccountComponent;
  Overview: IBankAccountComponent;
  AuthorizedUsers: IBankAccountComponent;
  PaymentsAndPayees: IBankAccountComponent;
  PayAch: IBankAccountComponent;
  PayWire: IBankAccountComponent;
  SuccessAch: IBankAccountComponent;
  SuccessWire: IBankAccountComponent;
  ReviewAch: IBankAccountComponent;
  ReviewWire: IBankAccountComponent;
  DepositCheck: IBankAccountComponent;
  ReviewDepositCheck: IBankAccountComponent;
  BusinessDetails: IBankAccountComponent;
  LocationDetails: IBankAccountComponent;
  Reports: IBankAccountComponent;
}

export const BankAccountComponents: IBankAccountComponents = {
  Dashboard: {
    id: nanoid(),
    path: BankAccountRoutes.Dashboard,
    component: () => <LazyAccountDashboard />,
  },
  Overview: {
    id: nanoid(),
    path: BankAccountRoutes.Overview,
    component: () => <LazyAccountOverview />,
  },
  SystemUsers: {
    id: nanoid(),
    path: BankAccountRoutes.SystemUsers,
    component: (prop) => <LazySystemUsers systemUser={prop} />,
  },
  AuthorizedUsers: {
    id: nanoid(),
    path: BankAccountRoutes.AuthorizedUsers,
    component: () => <LazyAuthorizedUsers />,
  },
  PaymentsAndPayees: {
    id: nanoid(),
    path: BankAccountRoutes.PaymentsAndPayees,
    component: () => <LazyPaymentsAndPayees />,
  },
  PayAch: {
    id: nanoid(),
    path: BankAccountRoutes.PayAch,
    component: () => <LazyPayment paymentType="ACH" />,
  },
  PayWire: {
    id: nanoid(),
    path: BankAccountRoutes.PayWire,
    component: () => <LazyPayment paymentType="Wire" />,
  },
  SuccessAch: {
    id: nanoid(),
    path: BankAccountRoutes.SuccessAch,
    component: () => <LazyPaymentSuccess paymentType="ACH" />,
  },
  SuccessWire: {
    id: nanoid(),
    path: BankAccountRoutes.SuccessWire,
    component: () => <LazyPaymentSuccess paymentType="Wire" />,
  },
  ReviewAch: {
    id: nanoid(),
    path: BankAccountRoutes.ReviewAch,
    component: () => <LazyReview transferType="ACH" />,
  },
  ReviewWire: {
    id: nanoid(),
    path: BankAccountRoutes.ReviewWire,
    component: () => <LazyReview transferType="Wire" />,
  },
  DepositCheck: {
    id: nanoid(),
    path: BankAccountRoutes.DepositCheck,
    component: () => <LazyDepositCheck />,
  },
  ReviewDepositCheck: {
    id: nanoid(),
    path: BankAccountRoutes.CompleteDepositCheck,
    component: () => <LazyCompleteDepositCheck />,
  },
  BusinessDetails: {
    id: nanoid(),
    path: BankAccountRoutes.BankAccountDetails,
    component: () => <LazyBankAccountDetails />,
  },
  LocationDetails: {
    id: nanoid(),
    path: BankAccountRoutes.LocationDetails,
    component: () => <LazyLocationDetails />,
  },
  Reports: {
    id: nanoid(),
    path: BankAccountRoutes.Reports,
    component: () => <LazyReports />,
  },
};
