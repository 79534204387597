import useUpdateBusinessDocument from '@/api/hooks/useUpdateBusinessDocument';

import type { BusinessDocumentType } from '@/types/business';

import { useCallback } from 'react';

interface UseHandleFileProps<DT extends BusinessDocumentType> {
  businessId?: string;
  onUpdateSuccess?: (
    documentType: DT,
    documentId: string,
    fileName: string,
  ) => void;
  onUploadSuccess?: (
    documentType: DT,
    documentId: string,
    fileName: string,
  ) => void;
  onError?: (error: Error, documentType: DT) => void;
}

export const useHandleDocument = <
  DocumentType extends BusinessDocumentType = BusinessDocumentType,
>(
  props?: UseHandleFileProps<DocumentType>,
): {
  handleFileUpdate: (
    documentType: DocumentType,
    file: React.ChangeEvent<HTMLInputElement>,
    businessId: string,
    facilityId?: string,
    documentId?: string,
  ) => void;
  handleFileUpload: (
    documentType: DocumentType,
    file: React.ChangeEvent<HTMLInputElement>,
    businessId: string,
    facilityId?: string,
    documentId?: string,
  ) => void;
} => {
  const updateBusinessDocument = useUpdateBusinessDocument<DocumentType>();

  const handleFileUpload = useCallback(
    (
      documentType: DocumentType,
      file: React.ChangeEvent<HTMLInputElement>,
      businessId: string,
      facilityId?: string,
      documentId?: string,
    ): void => {
      const fileToUpload = file.target.files?.item(0);

      if (fileToUpload == null) return;

      const formData = new FormData();

      formData.append('document', fileToUpload);
      formData.append('documentType', documentType);
      if (facilityId != null) {
        formData.append('facilityId', facilityId);
      }

      const businessIdToUse = businessId ?? props?.businessId;

      updateBusinessDocument.mutate(
        {
          businessId: businessIdToUse,
          businessDocument: formData,
          documentId,
        },
        {
          onSuccess(document) {
            const { documentType, fileName, syncteraDocumentId } = document;

            props?.onUploadSuccess?.(
              documentType,
              syncteraDocumentId,
              fileName,
            );
          },
          onError(error) {
            props?.onError?.(error, documentType);
          },
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props?.businessId],
  );

  const handleFileUpdate = useCallback(
    (
      documentType: DocumentType,
      file: React.ChangeEvent<HTMLInputElement>,
      businessId: string,
      facilityId?: string,
      documentId?: string,
    ): void => {
      handleFileUpload(documentType, file, businessId, facilityId, documentId);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props?.businessId],
  );

  return { handleFileUpdate, handleFileUpload };
};
