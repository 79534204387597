import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MaterialUIRadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { Controller } from 'react-hook-form';
import { CSS_VARS } from '@/constants/css-vars';
import OptionListIterator from './components/OptionListIterator';

import type { RadioGroupProps } from './types';

function RadioGroup<OptionValue, FormSchema extends Record<string, unknown>>(
  props: RadioGroupProps<OptionValue, FormSchema> & {
    disabled?: boolean;
    errorFlag?: boolean;
  },
): JSX.Element {
  const {
    control,
    label,
    name,
    optionListWrapper: OptionListWrapper,
    options,
    optionWrapper: OptionWrapper,
    verticalLayout = false,
    disabled = false,
    errorFlag = false,
  } = props;

  const labelId = `${name}-label`;

  return (
    <FormControl disabled={disabled ?? false}>
      {typeof label === 'string' && (
        <FormLabel id={labelId}>
          <Typography gutterBottom variant="h5">
            {label}
          </Typography>
        </FormLabel>
      )}

      <Controller<FormSchema, typeof name>
        control={control}
        name={name}
        render={({ field }) => (
          <MaterialUIRadioGroup
            {...field}
            aria-labelledby={labelId}
            row={!verticalLayout}
            sx={
              errorFlag
                ? {
                    border: '1px',
                    borderColor: `var(${CSS_VARS.Colors.Error.Dark})`,
                    borderStyle: 'solid',
                  }
                : undefined
            }
          >
            <OptionListIterator
              optionList={options}
              optionListWrapper={OptionListWrapper}
              optionWrapper={OptionWrapper}
            />
          </MaterialUIRadioGroup>
        )}
      />
    </FormControl>
  );
}

export default RadioGroup;
