import type {
  GridColumnHeaderParams,
  GridColumns,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import ColumnHeader from '@components/PayeesTable/components/ColumnHeader';
import Chip from '@mui/material/Chip';
import { ICONS } from '@/constants/icons';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

export enum SystemUsersRole {
  BUSINESS_OPERATOR = 'Business Operator',
  BUSINESS_MANAGER = 'Business Manager',
  WALLET_OPERATOR = 'Wallet Operator',
}

export enum SystemUsersValue {
  BUSINESS_OPERATOR = 'BUSINESS_OPERATOR',
  BUSINESS_MANAGER = 'BUSINESS_MANAGER',
  WALLET_OPERATOR = 'WALLET_OPERATOR',
}

export enum SystemUsersStatuses {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  DEACTIVATED = 'DEACTIVATED',
}

export enum SystemUsersStatusValue {
  ACTIVE = 'Active',
  PENDING = 'Pending',
  DEACTIVATED = 'Deactivated',
}

interface GridRow {
  id: string;
  name: string;
  role: SystemUsersRole;
  location: string[];
  status: SystemUsersStatuses;
  manage: () => void;
}

const CHIP_COLORS: Record<string, string> = {
  ACTIVE: '#e1f4ea',
  PENDING: '#FFF0C5',
  DEACTIVATED: '#F1F1F1',
};

export const columns: GridColumns<GridRow> = [
  {
    field: 'name',
    headerAlign: 'left',
    headerName: 'Name',
    width: 225,
    sortable: true,
    renderHeader: (params: GridColumnHeaderParams<string, GridRow>) => {
      const { headerName } = params.colDef;

      return <ColumnHeader>{headerName}</ColumnHeader>;
    },
  },
  {
    field: 'role',
    headerAlign: 'left',
    headerName: 'Role',
    width: 180,
    sortable: true,
    renderCell: (params: GridRenderCellParams<SystemUsersValue, GridRow>) => {
      const role = (params.value as SystemUsersValue) ?? '';
      const value = SystemUsersRole[role];
      return <Typography>{value}</Typography>;
    },
    renderHeader: (params: GridColumnHeaderParams<string, GridRow>) => {
      const { headerName } = params.colDef;

      return <ColumnHeader>{headerName}</ColumnHeader>;
    },
  },
  {
    field: 'location',
    headerAlign: 'left',
    headerName: 'Location',
    width: 200,
    sortable: true,
    renderCell: (params: GridRenderCellParams<string[] | string, GridRow>) => {
      const locationVal = params.value ?? [];
      let location;
      if (locationVal === 'all' || locationVal.length > 1) {
        location = 'All facilities';
      } else {
        const [firstLoc] = locationVal;
        location = (firstLoc as string) ?? '';
      }
      return <Typography>{location}</Typography>;
    },
    renderHeader: (params: GridColumnHeaderParams<string, GridRow>) => {
      const { headerName } = params.colDef;

      return <ColumnHeader>{headerName}</ColumnHeader>;
    },
  },
  {
    field: 'status',
    align: 'center',
    headerAlign: 'center',
    headerName: 'Status',
    sortable: false,
    width: 150,
    renderCell: (
      params: GridRenderCellParams<SystemUsersStatuses, GridRow>,
    ) => {
      const userStatus = (params.value as SystemUsersStatuses) ?? '';
      const color = CHIP_COLORS[userStatus];
      return userStatus ? (
        <Chip
          label={SystemUsersStatusValue[userStatus]}
          sx={{
            backgroundColor: color,
          }}
        />
      ) : (
        ''
      );
    },
    renderHeader: (params: GridColumnHeaderParams<string, GridRow>) => {
      const { headerName } = params.colDef;

      return <ColumnHeader>{headerName}</ColumnHeader>;
    },
  },
  {
    field: 'manage',
    align: 'center',
    headerAlign: 'center',
    headerName: 'Manage',
    filterable: false,
    sortable: false,
    renderCell: (params: GridRenderCellParams<() => void>): JSX.Element => (
      <IconButton onClick={params.value}>
        <ICONS.CheveronRightIcon color="#007e9e" fontSize="1.5em" />
      </IconButton>
    ),
    renderHeader: (params: GridColumnHeaderParams<string, GridRow>) => {
      const { headerName } = params.colDef;

      return <ColumnHeader>{headerName}</ColumnHeader>;
    },
  },
];
