import OutlinedInput, {
  outlinedInputClasses,
} from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';

import { CSS_VARS } from '@/constants/css-vars';

export const StyledInputText = styled(OutlinedInput)`
  & .${outlinedInputClasses.notchedOutline} {
    border-color: var(${CSS_VARS.Colors.Gray[200]});
    border-radius: 0.4rem;
    color: var(${CSS_VARS.Colors.BlackBlue});

    &[aria-disabled='true'] {
      background-color: #e4efff;
      border-color: #e4e4e4;
      color: var(${CSS_VARS.Colors.BrandBlue[400]});
    }

    &[aria-readonly='true'] {
      color: var(${CSS_VARS.Colors.BrandBlue[400]});
      opacity: 0.4;
    }

    & > input {
      border-color: inherit;
    }
  }

  &.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline} {
    border-color: var(${CSS_VARS.Colors.BrandBlue[300]});
    caret-color: var(${CSS_VARS.Colors.BrandBlue[300]});
    outline: none;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const inputStyles: React.CSSProperties = {
  borderRadius: 'inherit',
  borderWidth: '0.1rem',
  padding: '1.2rem 1rem',
};
