import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { BusinessDocument, BusinessDocumentType } from '@/types/business';

import getBusinessDocuments from './getBusinessDocuments';

const useGetBusinessDocuments = <
  DocumentType extends BusinessDocumentType = BusinessDocumentType,
>(
  businessId: string,
  facilityId?: string,
  queryOptions?: UseQueryOptions<Array<BusinessDocument<DocumentType>>, Error>,
): UseQueryResult<Array<BusinessDocument<DocumentType>>, Error> => {
  return useQuery<Array<BusinessDocument<DocumentType>>, Error>(
    ['getBusinessDocuments'],
    // eslint-disable-next-line @typescript-eslint/promise-function-async
    () => getBusinessDocuments(businessId, facilityId),
    queryOptions,
  );
};

export default useGetBusinessDocuments;
