import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import type { BusinessApplicationProgress } from '@/types/business';
import { useQuery } from '@tanstack/react-query';
import getBusinessApplicationProgress from './getBusinessApplicationProgress';

const useGetBusinessApplicationProgress = (
  businessId: string,
  queryOptions?: UseQueryOptions<BusinessApplicationProgress, Error>,
): UseQueryResult<BusinessApplicationProgress, Error> => {
  return useQuery<BusinessApplicationProgress, Error>(
    ['getBusinessApplicationProgress'],
    // eslint-disable-next-line @typescript-eslint/return-await, @typescript-eslint/promise-function-async
    () => getBusinessApplicationProgress(businessId),
    queryOptions,
  );
};

export default useGetBusinessApplicationProgress;
