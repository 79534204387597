import * as z from 'zod';

import { OWN_OR_LEASING_OPTIONS } from './data';
import {
  addressWithMailingFormSchema,
  addressWithOutMailingFormSchema,
} from '@components/Forms/Address/data';

export const formSchema = z.object({
  'own-or-leasing': z.enum(OWN_OR_LEASING_OPTIONS),
});

export type FormSchema = z.infer<typeof formSchema>;

export const facilityAddressWithMailingFormSchema =
  addressWithMailingFormSchema.merge(formSchema);
export const facilityAddressWithOutMailingFormSchema =
  addressWithOutMailingFormSchema.merge(formSchema);

export type FacilityWithMailingAddress = z.infer<
  typeof facilityAddressWithMailingFormSchema
>;
export type AddressesFormSchema =
  | FacilityWithMailingAddress
  | z.infer<typeof facilityAddressWithOutMailingFormSchema>;
