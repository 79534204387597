import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { Business } from '@/types/business';
import type { SystemUserAuthResponse, User } from '@/types/user';

import getAuthenticatedUser from './getAuthenticatedUser';

const useAuthenticatedUser = (
  queryOptions?: UseQueryOptions<
    User & { businesses: Business[] } & { SystemUser: SystemUserAuthResponse },
    Error
  >,
): UseQueryResult<
  User & { businesses: Business[] } & { SystemUser: SystemUserAuthResponse },
  Error
> =>
  useQuery<
    User & { businesses: Business[] } & { SystemUser: SystemUserAuthResponse },
    Error
  >(['getAuthenticatedUser'], getAuthenticatedUser, queryOptions);

export default useAuthenticatedUser;
