import useAuthenticatedUser from '@/api/hooks/useAuthenticateUser';
import useGetApplicationFeedbacks from '@/api/hooks/useGetApplicationFeedbacks';
import useGetBusiness from '@/api/hooks/useGetBusiness';
import useGetBusinessApplicationProgress from '@/api/hooks/useGetBusinessApplicationProgress';
import useGetBusinessDocuments from '@/api/hooks/useGetBusinessDocuments';
import useGetBusinessFacilityInformation from '@/api/hooks/useGetBusinessFacilityInformation';
import useGetBusinessValidation from '@/api/hooks/useGetBusinessValidation';
import { ApplicationStatusEnum } from '@/api/hooks/useGetBusinessValidation/type';
import useUpdateBusinessApplicationProgress from '@/api/hooks/useUpdateBusinessApplicationProgress';
import useUpdateFacilityInformation from '@/api/hooks/useUpdateFacilityInformation';
import RadioGroup from '@/components/Forms/RadioGroup';
import { FIXED_COUNTRY } from '@/constants/misc';
import { Router } from '@/constants/router';
import type { StatesEmpty, US_STATES } from '@/constants/us-states';
import type { BusinessDocumentType } from '@/types/business';
import { useHandleDocument } from '@/utils/hooks/useHandleDocument';
import AddressForm from '@components/Forms/Address/AddressForm';
import type { AddressFormSchema } from '@components/Forms/Address/AddressForm/type';
import MailingAddressForm from '@components/Forms/Address/MailingAddressForm';
import type { MailingAddressFormSchema } from '@components/Forms/Address/MailingAddressForm/type';
import type { CustomRegister } from '@components/Forms/Address/types';
import Checkbox from '@components/Forms/Checkbox';
import ControlBar from '@components/UI/ControlBar';
import { zodResolver } from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import type { SelectChangeEvent } from '@mui/material/Select';
import {
  AddressesFormSchema,
  facilityAddressWithMailingFormSchema,
  facilityAddressWithOutMailingFormSchema,
} from '@pages/Facilities/Sections/Details/Address/schema';
import { BUSINESS_DETAILS_SECTION_ID } from '@pages/Facilities/Sections/Details/types';
import { getFacilityDocumentItem } from '@pages/Facilities/Sections/facilityUtility';
import UploadDocuments from '@pages/Facilities/components/UploadDocuments';
import { useFacilitySectionData } from '@pages/Facilities/hooks/useFacilitySectionData';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import {
  ADDRESS_PAGE_ID,
  getNormalizedData,
  ownOrLeasingOptions,
  setFacilityAddressValues,
} from './data';

type DocumentType = Extract<BusinessDocumentType, 'property_title'>;

const DOCUMENT_TYPE: DocumentType = 'property_title';

function FacilityAddress(): JSX.Element {
  const navigate = useNavigate();
  const [isMailingAddress, setIsMailingAddress] = useState(false);
  const [disableUploadButton, setDisableUploadButton] =
    useState<boolean>(false);

  const { data: authenticatedUser } = useAuthenticatedUser();
  const { businesses } = authenticatedUser ?? {};
  const { id: businessId } = businesses?.[0] ?? {};
  const { facilityId } = useFacilitySectionData();
  const updateFacilityInformation = useUpdateFacilityInformation();

  const updateBusinessApplicationProgress =
    useUpdateBusinessApplicationProgress();

  const {
    data: businessApplicationProgress,
    refetch: refetchBusinessProgress,
  } = useGetBusinessApplicationProgress(businessId ?? '', {
    enabled: businessId != null,
  });

  const [errorMessage, setErrorMessage] = useState<string>();

  const {
    data: businessFacilityInformation,
    isLoading: isBusinessFacilityLoading,
  } = useGetBusinessFacilityInformation(
    businessId ?? '',
    facilityId ?? '',
    BUSINESS_DETAILS_SECTION_ID,
    ADDRESS_PAGE_ID,
    { enabled: businessId != null && facilityId != null },
  );

  const [usStateAddress, setUsStateAddress] = useState<StatesEmpty>('');

  const [usStateMailingAddress, setUsStateMailingAddress] =
    useState<StatesEmpty>('');

  const handleStateAddressChange = (data: SelectChangeEvent<unknown>): void => {
    const state = data.target.value as typeof US_STATES[number];
    setValue('state', state);
    setUsStateAddress(state);
  };

  const handleMailingStateAddressChange = (
    data: SelectChangeEvent<unknown>,
  ): void => {
    const state = data.target.value as typeof US_STATES[number];
    setValue('mailing-state', state);
    setUsStateMailingAddress(state);
  };

  const { data: documentList, refetch: refetchDocuments } =
    useGetBusinessDocuments<DocumentType>(businessId ?? '', facilityId ?? '', {
      enabled: businessId != null && facilityId != null,
    });

  const { data: businessValidation } = useGetBusinessValidation(
    businessId ?? '',
    {
      enabled: businessId != null,
    },
  );

  const { data: applicationFeedbacks } = useGetApplicationFeedbacks(
    facilityId ?? '',
    {
      enabled:
        facilityId != null &&
        businessValidation?.status ===
          ApplicationStatusEnum.REVIEW_INFO_REQUESTED,
    },
  );

  const propertyTitleDocument = documentList?.find(
    (document) => document.documentType === DOCUMENT_TYPE,
  );

  const handleDocument = useHandleDocument<DocumentType>({
    onUploadSuccess() {
      refetchDocuments({ throwOnError: true })
        .then(() => {
          setDisableUploadButton(false);
          setErrorMessage(undefined);
        })
        .catch(console.error);
    },
    onUpdateSuccess() {
      refetchDocuments({ throwOnError: true })
        .then(() => {
          setDisableUploadButton(false);
          setErrorMessage(undefined);
        })
        .catch(console.error);
    },
    onError(error) {
      setDisableUploadButton(false);
      if (error.message === 'Unprocessable Entity') {
        setErrorMessage('File too big, it should be no bigger than 32mb!');
      } else {
        setErrorMessage(error.message);
      }
    },
  });

  const getValidationSchema = z.lazy(() =>
    isMailingAddress
      ? facilityAddressWithMailingFormSchema
      : facilityAddressWithOutMailingFormSchema,
  );

  const { setTitle, facilityName } = useFacilitySectionData({
    description: 'Physical Address for This Location',
    pageNumber: 10,
  });

  useEffect(() => {
    if (typeof facilityName !== 'string') return;
    setTitle(`Tell us about ${facilityName}.`);
  }, [facilityName, setTitle]);

  const {
    getValues,
    setValue,
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<AddressesFormSchema>({
    resolver: zodResolver(getValidationSchema),
    defaultValues: {
      country: FIXED_COUNTRY,
      'street-address': '',
      'unit-number': '',
      city: '',
      state: undefined,
      'postal-code': '',
      'mailing-street-address': '',
      'mailing-unit-number': '',
      'mailing-city': '',
      'mailing-state': null,
      'mailing-postal-code': '',
      'mailing-country': FIXED_COUNTRY,
      'own-or-leasing': 'own',
    },
  });

  useEffect(() => {
    if (isBusinessFacilityLoading || businessFacilityInformation == null)
      return;

    setFacilityAddressValues(businessFacilityInformation, setValue);

    const mailingAddress = businessFacilityInformation.page.items.find(
      (item) => item.id === 'mailing-street-address',
    );
    setUsStateAddress(getValues('state') ?? '');

    setIsMailingAddress(mailingAddress != null);
    if (mailingAddress != null) {
      setUsStateMailingAddress(
        getValues('mailing-state') as typeof US_STATES[number],
      );
    }
  }, [
    isBusinessFacilityLoading,
    businessFacilityInformation,
    setValue,
    getValues,
  ]);

  const isLoading = updateFacilityInformation.isLoading;

  const { data: business } = useGetBusiness({ businessId });

  const hasFinishedOnboard = business?.finishedOnboard;

  const isApplicationResubmission =
    businessValidation?.status === ApplicationStatusEnum.REVIEW_INFO_REQUESTED;

  const onSubmitHandler: SubmitHandler<AddressesFormSchema> = (
    facilityData,
  ) => {
    if (businessId == null || facilityId == null) return;
    const sectionData = getNormalizedData(facilityData, isMailingAddress);

    if (propertyTitleDocument != null) {
      const label = 'Property Title or Lease Contract';
      const id = 'property_title_or_lease_contract';
      sectionData.page.items.push(
        getFacilityDocumentItem(
          label,
          id,
          propertyTitleDocument.syncteraDocumentId,
        ),
      );
    }

    updateFacilityInformation.mutate(
      {
        businessId,
        facilityId,
        section: sectionData,
      },
      {
        async onSuccess() {
          await updateBusinessApplicationProgress.mutateAsync(
            {
              businessId,
              pagesCompleted:
                businessFacilityInformation?.id !== '' ||
                authenticatedUser?.businesses?.[0]?.finishedOnboard
                  ? undefined
                  : (businessApplicationProgress?.pagesCompleted ?? 0) + 1,
            },
            {
              onSuccess() {
                refetchBusinessProgress().catch(console.error);
                navigate(Router.Onboarding.Facilities.Details.SoftwareLicense);
              },
            },
          );
        },
      },
    );
  };

  const handleUpload: React.ComponentProps<
    typeof UploadDocuments<DocumentType>
  >['onFileUpload'] = (documentType, file) => {
    if (businessId == null || facilityId == null) return;
    setDisableUploadButton(true);
    handleDocument.handleFileUpload(documentType, file, businessId, facilityId);
  };

  const handleUpdate: React.ComponentProps<
    typeof UploadDocuments<DocumentType>
  >['onFileUpdate'] = (documentType, file, documentId) => {
    if (businessId == null) return;
    setDisableUploadButton(true);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    handleDocument.handleFileUpdate(
      documentType,
      file,
      businessId,
      undefined,
      documentId,
    );
  };

  const handleMailingAddressCheckboxChange = (): void => {
    setIsMailingAddress((currentState) => !currentState);
  };

  return (
    <>
      <AddressForm
        stateAddress={usStateAddress}
        handleAddressChange={handleStateAddressChange}
        errors={errors}
        register={register as CustomRegister<AddressFormSchema>}
        feedbackFields={applicationFeedbacks ?? []}
        isUnderReview={
          businessValidation?.status ===
          ApplicationStatusEnum.REVIEW_INFO_REQUESTED
        }
      />
      <Box
        sx={{
          marginBottom: '2rem',
        }}
      >
        <Checkbox
          checked={isMailingAddress}
          label="Mailing address is different"
          sx={{
            color: '#254d73',
            '&.Mui-checked': {
              color: '#254d73',
            },
          }}
          onChange={handleMailingAddressCheckboxChange}
          disabled={
            businessValidation?.status ===
            ApplicationStatusEnum.REVIEW_INFO_REQUESTED
          }
        />
      </Box>

      {isMailingAddress && (
        <MailingAddressForm
          stateMailingAddress={usStateMailingAddress}
          handleMailingStateAddressChange={handleMailingStateAddressChange}
          errors={errors}
          register={register as CustomRegister<MailingAddressFormSchema>}
          feedbackFields={applicationFeedbacks ?? []}
          isUnderReview={
            businessValidation?.status ===
            ApplicationStatusEnum.REVIEW_INFO_REQUESTED
          }
        />
      )}

      <RadioGroup
        control={control}
        label="Do you own or lease this property?"
        name="own-or-leasing"
        options={ownOrLeasingOptions}
        disabled={
          businessValidation?.status ===
          ApplicationStatusEnum.REVIEW_INFO_REQUESTED
            ? !(applicationFeedbacks ?? []).filter(
                (feedback) => feedback.section === 'own-or-leasing',
              ).length
            : false
        }
        errorFlag={
          businessValidation?.status ===
          ApplicationStatusEnum.REVIEW_INFO_REQUESTED
            ? !!(applicationFeedbacks ?? []).filter(
                (feedback) => feedback.section === 'own-or-leasing',
              ).length
            : false
        }
      />

      <UploadDocuments
        required
        documentType={DOCUMENT_TYPE}
        label="Property Title or Lease Contract"
        disabled={
          disableUploadButton ||
          businessValidation?.status ===
            ApplicationStatusEnum.REVIEW_INFO_REQUESTED
        }
        fileName={propertyTitleDocument?.fileName}
        documentId={propertyTitleDocument?.syncteraDocumentId}
        onFileUpdate={handleUpdate}
        onFileUpload={handleUpload}
        errorMessage={errorMessage}
      />

      <ControlBar
        showInfoText={!hasFinishedOnboard && !isApplicationResubmission}
        backButtonProps={{
          onClick: () => {
            navigate(Router.Onboarding.Facilities.Details.Type);
          },
        }}
        nextButtonProps={{
          disabled:
            propertyTitleDocument == null ||
            updateBusinessApplicationProgress.isLoading ||
            updateFacilityInformation.isLoading,
          onClick: handleSubmit(onSubmitHandler),
        }}
        isLoading={isLoading}
      />
    </>
  );
}

export default FacilityAddress;
