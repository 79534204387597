import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { VERSION } from '@/constants/env';
const legalLanguage = 'Banking services provided by Regent Bank, Member FDIC.';
const VersionNumber = VERSION;
const Footer = (): JSX.Element => {
  return (
    <Box
      component="footer"
      gridArea="footer"
      width="100%"
      padding="2rem"
      position="relative"
    >
      <Typography
        textAlign="center"
        fontWeight="500"
        fontSize="13px"
        lineHeight="1.5rem"
      >
        {legalLanguage}
      </Typography>
      <Box position="absolute" right="2rem" bottom="2rem">
        <Typography
          textAlign="right"
          fontWeight="800"
          fontSize="10px"
          lineHeight="1.5rem"
        >
          {VersionNumber}
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
