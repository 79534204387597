import Typography from '@mui/material/Typography';

import { CSS_VARS } from '@/constants/css-vars';

interface ChildSectionsCounterProps {
  currentSection: number | null;
  totalSections: number;
}

const ChildSectionsCounter = (
  props: ChildSectionsCounterProps,
): JSX.Element => {
  const { currentSection, totalSections } = props;

  return (
    <Typography
      color={`var(${CSS_VARS.Colors.Gray[400]})`}
      fontWeight={700}
      variant="body2"
    >
      {currentSection} of {totalSections}
    </Typography>
  );
};

export default ChildSectionsCounter;
